<template>
  <a-config-provider :theme="themes[type]">
    <a-button v-bind="$attrs" :type="_type" :size="size" :disabled="disabled" :loading="loading" :block="block" :shape="shape" class="df-btn">
      <slot></slot>
    </a-button>
  </a-config-provider>
</template>

<script setup lang="ts">
import type { ThemeConfig } from 'ant-design-vue/es/config-provider/context';
import type { PropType } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  type: {
    type: String as PropType<'primary' | 'special' | 'text' | 'default' | 'link' | 'alert'>,
    default: 'default',
  },
  size: {
    type: String as PropType<'large' | 'middle' | 'small'>,
    default: 'large',
  },
  shape: {
    type: String as PropType<'square' | 'circle' | 'round' | 'default'>,
    default: 'round',
  },
  disabled: Boolean,
  loading: Boolean,
  block: Boolean,
});

const _type = computed<any>(() => (['primary', 'special', 'alert'].includes(props.type) ? 'primary' : props.type));
const shape = computed<any>(() => (props.shape !== 'square' ? props.shape : 'default'));
const themes: Record<string, ThemeConfig> = {
  default: {
    components: {
      Button: {
        colorText: '#1A1A1A',
        colorBorder: '#1A1A1A',

        colorBgContainer: 'transparent',

        colorPrimaryHover: '#353538',
        colorPrimaryBgHover: '#F0F1F5',

        colorPrimaryActive: '#1A1A1A',

        colorBgContainerDisabled: '#FFF',

        colorTextDisabled: '#1A1A1A',
      },
    },
  },
  primary: {
    components: {
      Button: {
        colorPrimary: '#1A1A1A',
        colorPrimaryHover: '#353538',
        colorPrimaryActive: '#000',
        colorBgContainerDisabled: '#1A1A1A',
        colorTextDisabled: '#FFF',
      },
    },
  },
  special: {
    components: {
      Button: {
        colorPrimary: '#FF0035',
        colorPrimaryHover: '#FF4159',
        colorPrimaryActive: '#EF0041',
        colorBgContainerDisabled: '#FF0035',
        colorTextDisabled: '#FFF',
      },
    },
  },
  text: {
    components: {
      Button: {
        colorText: '#1A1A1A',
        colorTextDisabled: '#CDCDCD',

        colorBgTextHover: '#F0F1F5',
        colorBgTextActive: '#E9EAF0',
      },
    },
  },
  link: {
    components: {
      Button: {
        colorText: '#9E9E9E',
        colorTextDisabled: '#9E9E9E',
        colorBgTextHover: '#353538',
        colorBgTextActive: '#000000',
      },
    },
  },
  alert: {
    components: {
      Button: {
        colorPrimary: '#FF7C23',
        colorPrimaryHover: '#FF9432',
        colorPrimaryActive: '#FF7C23',
        colorBgContainerDisabled: '#FF7C23',
        colorTextDisabled: '#FFF',
      },
    },
  },
};
</script>

<style scoped lang="less">
.df-btn {
  font-size: 14px;
  font-family: 'Open Sans SemiBold', serif;
  &.ant-btn-default {
    &:not(:disabled):hover {
      background-color: #f0f1f5;
    }
  }
  &.ant-btn-default,
  &.ant-btn-primary {
    &:disabled {
      opacity: 0.4;
    }
  }
  &.ant-btn-round.ant-btn-lg {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
</style>
