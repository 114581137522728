import { isNil } from 'lodash-es';
import { nextFrame } from './next-frame';
import { tryAsyncCall } from './call';
import { PromiseController } from './promise';

type TransitionHook = (el: HTMLElement) => Promise<void> | void;
export interface TransitionHooks {
  beforeEnter?: TransitionHook;
  enter?: TransitionHook;
  afterEnter?: TransitionHook;
  beforeLeave?: TransitionHook;
  leave?: TransitionHook;
  afterLeave?: TransitionHook;
}

export class Transition {
  readonly #hooks: TransitionHooks;
  constructor(hooks?: TransitionHooks) {
    this.#hooks = !isNil(hooks) ? { ...hooks } : {};
  }

  async enter(el: HTMLElement, hooks: TransitionHooks = {}) {
    const controller = new PromiseController();
    try {
      Object.assign(el.style, { display: 'none' });
      await nextFrame();
      await this.#hooks.beforeEnter?.(el);
      await hooks.beforeEnter?.(el);
      await nextFrame();
      Object.assign(el.style, { display: null });
      await nextFrame();
      await this.#hooks.enter?.(el);
      await hooks.enter?.(el);
      const listener = async () => {
        await tryAsyncCall(async () => {
          await this.#hooks.afterEnter?.(el);
          await hooks.afterEnter?.(el);
        });
        el.removeEventListener('transitionend', listener);
        controller.resolve();
      };
      el.addEventListener('transitionend', listener);
      return controller.promise;
    } catch (err) {
      controller.reject(err);
    }
  }

  async leave(el: HTMLElement, hooks: TransitionHooks = {}) {
    const controller = new PromiseController();
    try {
      Object.assign(el.style, { display: null });
      await this.#hooks.beforeLeave?.(el);
      await hooks.beforeLeave?.(el);
      await nextFrame();
      await this.#hooks.leave?.(el);
      await hooks.leave?.(el);
      const listener = async () => {
        await tryAsyncCall(async () => {
          await this.#hooks.afterLeave?.(el);
          await hooks.afterLeave?.(el);
        });
        el.removeEventListener('transitionend', listener);
        Object.assign(el.style, { display: 'none' });
        controller.resolve();
      };
      el.addEventListener('transitionend', listener);
      return controller.promise;
    } catch (err) {
      controller.reject(err);
    }
  }

  async toggle(el: HTMLElement, show = true, hooks: TransitionHooks = {}) {
    if (show) {
      await this.enter(el, hooks);
    } else {
      await this.leave(el, hooks);
    }
  }

  static async play(el: HTMLElement, hooks: TransitionHooks = {}) {
    const controller = new PromiseController();
    try {
      await hooks.beforeEnter?.(el);
      await nextFrame();
      await hooks.enter?.(el);
      const listener = async () => {
        await tryAsyncCall(async () => {
          await hooks.afterEnter?.(el);
        });
        el.removeEventListener('transitionend', listener);
        controller.resolve();
      };
      el.addEventListener('transitionend', listener);
      return controller.promise;
    } catch (err) {
      controller.reject(err);
    }
  }
}
