<template>
  <div class="flex">
    <template v-if="currentPlan === SubscriptionPlan.FREE">
      <div class="bg-text-primary bg-opacity-90 px-2 py-1 font-sans-bold text-level-4 text-[#FFF]">{{ originPaymentPrice }}</div>
      <div class="flex items-center px-2 py-1" style="background: linear-gradient(100.11deg, #ffdfa8 -8.86%, #f9cf88 100%)">
        <div class="mr-1 flex items-center rounded-[3px] bg-text-primary p-1">
          <img :src="PRO_TEXT" alt="" class="h-[10px] w-[21px]" />
        </div>
        <span class="font-sans-bold text-level-4 text-text-primary">
          {{ isPGC ? 'Free' : `${benefitPrice}` }}
        </span>
      </div>
    </template>
    <template v-else>
      <div class="flex items-center justify-center bg-text-primary bg-opacity-90 px-2">
        <img :src="currentPlan === SubscriptionPlan.PRO ? PRO_TEXT : PRO_PLUS_TEXT" alt="" class="h-[14px] w-[30px]" />
      </div>
      <div
        class="px-2 py-1 text-center font-sans-bold text-level-4 text-text-primary"
        style="background: linear-gradient(100.11deg, #ffdfa8 -8.86%, #f9cf88 100%)"
      >
        {{ isPGC ? 'Free' : `${benefitPrice}` }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { SubscriptionPlan } from '~/components/subscription/constant';
import PRO_TEXT from '@/assets/img/subscription/pro-text.svg';
import PRO_PLUS_TEXT from '@/assets/img/subscription/pro-plus-text.svg';
import { type ItemType, MakingAttr } from '~/types';
import { useSubscription } from '~/stores/subscription';

const props = defineProps({
  item: {
    type: Object as PropType<ItemType>,
    default: () => ({}),
  },
  priceInfo: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
});

const isPGC = computed(() => {
  return props.item.subscriptionAttr === MakingAttr.PGC;
});

const subscription = useSubscription();
const currentPlan = computed(() => subscription.plan);
const priceForSub = computed(() => props.item.priceForSub);
const benefitPrice = computed(() => {
  // const _price = currentPlan.value === SubscriptionPlan.PRO_PLUS ? priceForSub.value?.proPrice : priceForSub.value?.proPlusPrice;
  const price = currentPlan.value !== SubscriptionPlan.PRO ? priceForSub.value?.proPlusPrice : priceForSub.value?.proPrice;
  return `$${(price ?? 0).toFixed(2)}`;
});
const originPaymentPrice = computed(() => (props.priceInfo.hasDiscount ? props.priceInfo.disCountPrice : props.priceInfo.origPrice));
</script>
