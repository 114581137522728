import { throttle } from 'lodash-es';

export function useCommon(options: any) {
  const defaultOpts = {
    firstElDataSet: 'first-el',
    lastElDataSet: 'last-el',
    step: 1,
  };
  const opts = options ? options : defaultOpts;

  // 初始化 IntersectionObserver
  const isLeftArrowShow = ref(false);
  const isRightArrowShow = ref(false);
  const observer = ref<IntersectionObserver | null>(null);
  const wrapperRef = ref();
  const itemRefs = ref<HTMLDivElement[]>();
  function initObserver() {
    observer.value = new IntersectionObserver(
      (entries) => {
        // console.log('entries :>> ', entries);
        entries.forEach((entry) => {
          // console.log('entry :>> ', entry);
          const target = entry.target as HTMLDivElement;
          // console.log('target.dataset.index :>> ', target.dataset['index']);
          // first-el
          if (target.dataset['index'] === opts.firstElDataSet) {
            if (entry.isIntersecting) {
              // console.log('first-el完全可见了');
              isLeftArrowShow.value = false;
            } else {
              isLeftArrowShow.value = true;
            }
          }

          // last-el
          if (target.dataset['index'] === opts.lastElDataSet) {
            if (entry.isIntersecting) {
              // console.log('last-el完全可见了');
              isRightArrowShow.value = false;
            } else {
              isRightArrowShow.value = true;
            }
          }
        });
      },
      {
        root: wrapperRef.value, // 需要与之交叉的根元素
        threshold: 0.99,
      },
    );

    // 需要观察的目标元素，只观察第一张和最后一张
    if (itemRefs.value) {
      const els = itemRefs.value;
      const len = els.length;
      // 小于4张不需要观察
      if (len >= 4) {
        els.forEach((el, index) => {
          if (index === 0 || index === len - 1) {
            observer.value?.observe(getComponentRootEl(el));
          }
        });
      }
    }
  }
  onMounted(() => {
    initObserver();
  });
  onUnmounted(() => {
    observer.value?.disconnect();
  });

  // 点击箭头滚动
  const onArrowClick = throttle((direction: -1 | 1) => {
    const wrapper = wrapperRef.value;
    const itemDom = getComponentRootEl(itemRefs.value?.[0]);
    if (wrapper && itemDom) {
      const rectWidth = itemDom.getBoundingClientRect().width;
      const distance = Math.floor(rectWidth);
      wrapper.scrollLeft += direction * (distance * opts.step);
    }
  }, 120);

  return {
    initObserver,
    wrapperRef,
    itemRefs,
    isLeftArrowShow,
    isRightArrowShow,
    onArrowClick,
  };
}
