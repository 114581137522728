<template>
  <div v-if="creatorList.length > 0" class="creators-column">
    <div
      class="m-auto mt-10 px-10 py-10"
      :style="{
        maxWidth: 'var(--maxBoxWidth)',
      }"
    >
      <div class="flex justify-between px-2">
        <span class="title text-level-1 font-sans-bold">Featured Creators</span>
        <div class="flex gap-4">
          <div
            class="h-12 w-12 flex justify-center items-center rounded-full"
            :class="[
              {
                'bg-[#fff]/30 cursor-not-allowed': !isLeftArrowShow,
                'bg-[#fff]/80 cursor-pointer': isLeftArrowShow,
              },
            ]"
            @click="onLeftClick"
          >
            <ClientOnly>
              <icon-font class="text-[26px]" type="icon-icon-right-m" />
            </ClientOnly>
          </div>
          <div
            class="h-12 w-12 flex justify-center items-center rounded-full"
            :class="[
              {
                'bg-[#fff]/30 cursor-not-allowed': !isRightArrowShow,
                'bg-[#fff]/80 cursor-pointer': isRightArrowShow,
              },
            ]"
            @click="onRightClick"
          >
            <ClientOnly>
              <icon-font class="text-[26px]" type="icon-a-icon-right-m1" />
            </ClientOnly>
          </div>
        </div>
      </div>

      <div ref="wrapperRef" class="box-wrapper mt-4 flex flex-nowrap overflow-auto">
        <div
          v-for="(item, index) in creatorList"
          ref="itemRefs"
          :key="index"
          class="card-item flex-shrink-0"
          :data-index="index === 0 ? 'first-el' : index === creatorList.length - 1 ? 'last-el' : null"
          :style="{
            width: 'var(--colBoxWidth)',
          }"
        >
          <div class="p-2 w-full h-full">
            <BlockCreatorItem source="homepage" :user-item="item" id-key="uid" @follow-change="handleFollowChange($event, item)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCommon, useCreators } from './hooks';

const { getCreatorList, creatorList, handleFollowChange } = useCreators();
const { wrapperRef, itemRefs, isLeftArrowShow, isRightArrowShow, onArrowClick } = useCommon({
  firstElDataSet: 'first-el',
  lastElDataSet: 'last-el',
  step: 3,
});

await getCreatorList();

// 激活时重新获取数据
onActivated(() => {
  getCreatorList();
});

const onLeftClick = () => {
  if (!isLeftArrowShow.value) {
    return;
  }
  onArrowClick(-1);
};
const onRightClick = () => {
  if (!isRightArrowShow.value) {
    return;
  }
  onArrowClick(1);
};
</script>

<style lang="less" scoped>
.creators-column {
  background-image: url('~/assets/img/home/user-box-bg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.box-wrapper {
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
