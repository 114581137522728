<template>
  <a-popover ref="triggerRef" :open="open" placement="bottom" :align="align" :auto-adjust-overflow="true" @open-change="onOpenChange">
    <slot></slot>
    <template #content>
      <div class="author-user-card font-sans">
        <!-- 用户基本信息 -->
        <div class="user-base flex-r-c">
          <div class="user-img" @click="handleClickUser">
            <img v-lazy="userInfo.headpic" class="rounded-full object-cover" :width="48" :height="48" alt="" />
          </div>
          <div class="user-info flex w-[calc(100%-48px)] flex-col justify-center">
            <div class="user-name overflow-hidden truncate break-all font-sans-semibold" @click="handleClickUser">
              {{ userInfo.name }}
            </div>
            <img v-if="isPremium" class="user-sale cursor-pointer" :src="PREMIUM_LABEL" />
            <!--            <img v-if="isPremium" class="user-sale cursor-pointer" :src="PREMIUM_LABEL" @click.stop.prevent="navigateTo('/creator/guide')" />-->
          </div>
        </div>

        <!-- 用户数据 -->
        <div class="user-data flex-r-c">
          <div v-for="item in userMakingInfo" :key="item.label" class="user-data-item">
            <div class="count font-sans-bold text-level-3 text-[#212121]">{{ item.count }}</div>
            <div class="label text-level-7 text-[#9E9E9E]">{{ item.label }}</div>
            <!-- 分割线 -->
            <div class="line"></div>
          </div>
        </div>

        <div class="user-follow">
          <follow-button
            v-if="userInfo?.id"
            :id="userInfo?.id"
            :name="userInfo?.name"
            :is-my-follow="userInfo?.isFollowed"
            :content-type="'user'"
            :source="'usercard'"
            @change="handleFollowChange"
          />
        </div>

        <div v-if="userMakings?.length > 0" class="user-making">
          <div
            v-for="(item, index) in userMakings"
            :key="index"
            class="user-making-item"
            :data-sensors-exposure-event-name="SENSORS_EVENT?.CONTENT_EXPOSURE"
            data-sensors-exposure-config-area_rate="0.5"
            data-sensors-exposure-config-stay_duration="2"
            data-sensors-exposure-config-repeated="true"
            :data-sensors-exposure-property-content_id="item.id"
            :data-sensors-exposure-property-content_name="item.title"
            :data-sensors-exposure-property-content_show_type="item.type"
            :data-sensors-exposure-property-content_tag="item?.contentTags || []"
            :data-sensors-exposure-property-publish_time="item.publishAt"
            :data-sensors-exposure-property-author_name="userInfo.name"
            :data-sensors-exposure-property-show_source="'usercard'"
            :data-sensors-exposure-property-block_id="blockId"
            :data-sensors-exposure-property-block_name="blockName"
            :data-sensors-exposure-property-service_type="item.type"
            :data-sensors-exposure-property-content_type="item.forSale ? 'paid' : 'free'"
            @click="handleClickMakingItem(item)"
          >
            <div class="wrap">
              <div class="content">
                <img v-lazy="getOssProcessedImage(item.cover, 'm_fill,w_240,h_180', 'webp')" class="img" alt="making" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </a-popover>
</template>

<script setup lang="tsx">
import type { PropType } from 'vue';
import { getUserInfoApi } from '~/api';
import PREMIUM_LABEL from '~/assets/img/premium-label.png';
import { SENSORS_EVENT } from '~/types';

const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
  blockId: {
    type: Number as PropType<number | null>,
    default: null,
  },
  blockName: {
    type: String as PropType<string | null>,
    default: null,
  },
  disabled: Boolean,
});

const { sensorContentClickEvent } = useSensors();

const userInfo = ref({
  id: null,
  name: '',
  headpic: '',
  isFollowed: false,
  isSaleMaker: false,
  isXthingsMaker: false,
  followersCount: 0,
  likesCount: 0,
  makingCount: 0,
  followingCount: 0,
  makings: [] as any,
});
const isPremium = computed(() => {
  return userInfo.value?.isSaleMaker;
});
const userMakings = computed(() => {
  const list = userInfo?.value?.makings?.slice(0, 4) || [];

  if (list.length === 3) {
    return list.slice(0, 2);
  }

  return list;
});
const userMakingInfo = computed(() => {
  return [
    {
      label: 'Followers',
      count: userInfo.value?.followersCount || 0,
    },
    {
      label: 'Likes',
      count: userInfo.value?.likesCount || 0,
    },
    {
      label: 'Assets',
      count: userInfo.value?.makingCount || 0,
    },
  ];
});

let loaded = false;
const queryUser = async () => {
  if (!props.userId) {
    return;
  }

  const uid = Number(props.userId);
  // console.log('查询用户信息，当前用户ID：', uid);

  try {
    const res = await getUserInfoApi(uid);
    const data = res.data;

    if (data?.value?.code !== 0) {
      throw new Error(data.value.message);
    }

    userInfo.value = data.value.data;

    loaded = true;

    // console.log('查询用户信息成功：', userInfo.value);
  } catch (err) {
    console.error(err);
  }
};

const handleFollowChange = (isFollowed: boolean) => {
  userInfo.value.isFollowed = isFollowed;
  queryUser(); // 更新用户信息
};

const handleClickMakingItem = (item: any) => {
  const url = `/${item.type}/${item.id}`;
  sensorContentClickEvent({
    content_id: item.id,
    content_name: item.title, // 内容名称
    content_tag: item.contentTags ?? [], // 内容标签
    publish_time: item.publishedAt, // 发布时间
    author_name: userInfo.value.name, // 作者名称
    author_id: userInfo.value.id as unknown as number, // 作者id
    service_type: item.type, // 所属板块
    link_url: url, // 跳转页面url
    block_name: 'usercard', // 栏目名称
  });
  navigateTo(url);
};

const handleClickUser = () => {
  navigateTo(`/profile/${props.userId}`);
};

let active = false;
const open = ref(false);
const onOpenChange = async (visible: boolean) => {
  if (!props.disabled) {
    return;
  }
  active = visible;
  if (active && !loaded) {
    await queryUser();
  }
  open.value = active;
};
const triggerRef = shallowRef();
const triggerElRef = computed(() => getComponentRootEl(triggerRef.value));
useEventListener(triggerElRef, 'mouseleave', () => {
  if (loaded) {
    return;
  }
  active = false;
});

const align = {
  overflow: {
    adjustX: true,
    adjustY: true,
    alwaysByViewport: true,
  },
};
</script>

<style lang="less" scoped>
.author-user-card {
  position: relative;
  width: 216px;

  :deep(.ant-skeleton-input) {
    width: 100% !important;
    height: 100% !important;
    min-width: auto;
    line-height: 1;
  }

  .flex-r-c {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .user-base {
    padding: 12px;
  }

  .user-img {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    cursor: pointer;
  }

  .user-name {
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    cursor: pointer;
  }

  .user-sale {
    width: 115px;
    margin-top: 4px;
  }

  .user-data {
    padding-top: 12px;
  }

  .user-data-item {
    flex: 1;
    text-align: center;
    position: relative;

    .line {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 38px;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:last-child {
      .line {
        display: none;
      }
    }
  }

  .user-follow {
    width: 216px;
    height: 36px;
    margin-top: 16px;

    :deep(.follow-button) {
      width: 100%;
      height: 100%;

      .df-btn {
        width: 100%;
        height: 100%;
        border-radius: 40px;

        .anticon {
          font-size: 16px !important;
        }
        .text {
          margin-left: 4px !important;
        }
        .text,
        .followed-text {
          font-size: 12px !important;
        }
      }
    }
  }

  .user-making {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 16px;
  }

  .user-making-item {
    position: relative;
    flex: 1;
    min-width: calc(50% - 2px);
    background-color: #ececec;
    margin-right: 2px;
    margin-bottom: 2px;
    cursor: pointer;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      display: block;
      background: rgba(0, 0, 0);
      opacity: 0;
      transition: opacity 0.2s ease-in;
      pointer-events: none;
    }
    &:hover::after {
      opacity: 0.2;
    }

    .wrap {
      position: relative;
      width: 100%;
      padding-bottom: 74.5%;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
