import type { MaybeRef } from 'vue';
import { isRef, unref, watch } from 'vue';
import type { Fn } from '~/types/fns';
import { isNil } from 'lodash-es';

export type EventSource = (Window & typeof globalThis) | Document | HTMLElement;
export type EventMap<S extends EventSource> = S extends Window
  ? WindowEventMap
  : S extends Document
  ? DocumentEventMap
  : S extends HTMLElement
  ? HTMLElementEventMap
  : Record<string, Event>;

export function useEventListener<S extends EventSource, T extends keyof EventMap<S>, L extends (ev: EventMap<S>[T]) => any>(
  source: MaybeRef<S | undefined>,
  type: T,
  listener: L,
  options: AddEventListenerOptions | boolean = true,
) {
  let s: EventSource | undefined;
  const _listener = listener as unknown as EventListenerOrEventListenerObject;
  let _cleanup: Fn<[], void>;
  const cleanup = () => {
    if (isRef(source)) {
      _cleanup();
    }
    if (isNil(s)) {
      return;
    }
    s.removeEventListener(type as string, _listener);
  };

  if (!isInClient()) {
    return cleanup;
  }

  if (isRef(source)) {
    _cleanup = watch(
      () => {
        const _s = unref(source);
        return ![window, document].includes(_s as any) ? getComponentRootEl(_s) : _s;
      },
      (_s, _old) => {
        if (!isNil(_old)) {
          _old.removeEventListener(type as string, _listener);
        }
        if (!isNil(_s)) {
          _s.addEventListener(type as string, _listener, options);
        }

        s = _s;
      },
    );
  } else if (!isNil(source)) {
    s = source;
    s.addEventListener(type as string, _listener, options);
  }

  tryOnUnMounted(cleanup);

  return cleanup;
}
