<template>
  <div ref="container" class="bottom-loading" :style="style">
    <a-row justify="center">
      <a-col v-if="loading">
        <a-spin :tip="tips" />
      </a-col>
      <a-col v-if="finished && !isHideBottomLine" :span="24">
        <a-divider dashed style="color: #999">This is the bottom line</a-divider>
      </a-col>
    </a-row>
  </div>
</template>
<script setup lang="ts">
const container = ref();
type PropsType = {
  loading: boolean;
  finished?: boolean;
  isHideBottomLine?: boolean;
  tips?: string;
  style?: string;
};

const props = withDefaults(defineProps<PropsType>(), {
  tips: 'Loading...',
  style: '',
});
type eventType = {
  (event: 'infinite'): void;
};
const emit = defineEmits<eventType>();

const observer = new IntersectionObserver(([entry]) => {
  if (entry && entry.isIntersecting && !props.loading && !props.finished) {
    emit('infinite');
  }
});

onMounted(() => {
  setTimeout(() => {
    observer.observe(container.value);
  }, 0);
});
onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<style lang="less" scoped>
.bottom-loading {
  padding-top: 1px; // 修复个人中心不触发底部加载的问题
}
</style>
