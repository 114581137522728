import { request, REQUEST_SEVER } from '@/utils/request';
import { defineCommonApi, defineFindApi } from '~/utils/fetch/define-api';

// 首页banner
export const getHomeBannerApi = defineCommonApi({
  url: '/efficacy/v1/data/type/community_banner/items',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any[]>(),
});

// 推荐位列表
export const getRecommendListApi = defineFindApi({
  url: '/community/v1/web/recommends',
  method: HttpMethod.GET,
  paramTy: ParamTy.OptionalQuery<{
    pageType: 'home' | 'primeHome' | '';
  }>(),
  respTy: RespTy.Json<any[]>(),
});

// 案例列表
export const getCaseListApi = defineFindApi({
  url: '/community/v1/web/home-column/makings',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any[]>(),
  paramTy: ParamTy.Query<{
    type: any;
  }>(),
});

// 创作者列表
export const getCreatorListApi = defineFindApi({
  url: '/community/v1/web/home-column/creators',
  method: HttpMethod.GET,
  paramTy: ParamTy.OptionalQuery<{
    pageType: 'home' | 'primeHome' | '';
  }>(),
  respTy: RespTy.Json<any[]>(),
});

// 获取板块内容
export const getBlockApi = defineFindApi({
  url: '/community/v1/web/blocks',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    category: string;
  }>(),
  respTy: RespTy.Json<any[]>(),
});

// 获取单个板块内容
export const getSlingleBlockApi = (params: { id: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/block',
    method: 'GET',
    params,
  });
};

// 获取单个板块内容 - 分页
export const getSlingleBlockByPageApi = (params: { id: number; current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/block/items',
    method: Methods.GET,
    params,
  });
};

// 获取创作列表
export const getMakingListApi = (params: any, config: any = {}) => {
  return request[REQUEST_SEVER.DEFAULT](
    {
      url: '/community/v1/web/makings',
      method: Methods.GET,
      params,
    },
    {
      ...config,
    },
  );
};

// 获取草稿箱
export const getDraftListApi = (params: { current: number; pageSize: number; forSale?: '0' | '1' | '' }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/draft',
    method: 'GET',
    params,
  });
};

// 删除草稿
export const deleteDraftApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}`,
    method: 'DELETE',
  });
};

// 获取移动端首页栏目数据
export const getMobileBlockList = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/block/mobile/feature',
    method: 'GET',
    params,
  });
};

/**
 * 更新案例数据 useFetch
 */
export const getBlockExtListApi = (
  ids: number[],
  opts?: {
    key?: string;
  },
) => {
  const url = '/community/v1/web/making/ext-data';
  const requestkey = opts?.key ? opts.key : url;
  return request[REQUEST_SEVER.DEFAULT](
    {
      url,
      method: Methods.POST,
      data: {
        ids,
      },
    },
    {
      key: requestkey, // 防止首页并发调用时取到缓存数据
    },
  );
};

/**
 * 更新案例数据 fetch
 */
export const getBlockExtDataListApi = defineFindApi({
  url: '/community/v1/web/making/ext-data',
  method: HttpMethod.POST,
  respTy: RespTy.Json<any>(),
  paramTy: ParamTy.Json<{
    ids: number[];
  }>(),
});
