<template>
  <div v-if="makingList.length" class="home-column mt-12">
    <div
      class="m-auto px-10"
      :style="{
        maxWidth: 'var(--maxBoxWidth)',
      }"
    >
      <div class="title pl-2 font-sans-bold text-level-1">{{ ColumnMap[columnType].title }}</div>
      <div class="column-wrapper mt-4 flex w-full flex-wrap overflow-auto pb-10">
        <div
          v-for="(item, index) in makingList"
          :key="index"
          class="card-item flex-shrink-0"
          :style="{
            width: 'var(--colBoxWidth2)',
          }"
        >
          <div class="h-full w-full p-2">
            <BlockCardItem
              class="h-full w-full"
              :making-item="item"
              :block-title="ColumnMap[columnType].title"
              :block-id="undefined"
              :is-show-user-card="true"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <df-btn
          :href="columnType !== ColumnTypeEnum.Premium ? `${siteUrl}/all?payTypes=${columnType}` : '/premium'"
          type="default"
          shape="round"
          size="large"
          :title="`See more creations in the category of ${ColumnMap[columnType].title}`"
          class="seemore-btn border-black-default font-sans-bold"
          @click.prevent="onSeeMoreClick"
        >
          <span>
            See more
            <i class="-indent-24 text-[0]">creations in the category of {{ ColumnMap[columnType].title }}</i>
          </span>
        </df-btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ColumnMap, ColumnTypeEnum } from './const';
import { useHomeColumn } from './hooks';

const siteUrl = import.meta.env.VITE_PUBLIC_SITE_URL;

type PropsType = {
  columnType: ColumnTypeEnum;
};
const props = withDefaults(defineProps<PropsType>(), {
  columnType: ColumnTypeEnum.Creations,
});

const { makingList, getMakingList, onSeeMoreClick, updateMakingList } = useHomeColumn({
  type: props.columnType,
});

await getMakingList();

// 激活时重新获取数据，并更新卡片内容
onActivated(() => {
  updateMakingList();
});
</script>

<style lang="less" scoped>
// lg: ≥992px
@media (min-width: 992px) {
  .card-item:nth-of-type(n + 10) {
    display: none;
  }
}
// xl: ≥1200px
@media (min-width: 1200px) {
  .card-item:nth-of-type(n + 9) {
    display: none;
  }
}
// xxl: ≥1600px
@media (min-width: 1600px) {
  .card-item:nth-of-type(n) {
    display: block;
  }
}

:deep(.seemore-btn) {
  width: 160px;
  height: 48px;
  font-size: 16px;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
