export const enum SubscriptionPlan {
  FREE = 0,
  PRO = 1,
  PRO_PLUS = 2,
}

export const SubscriptionPlanMap = {
  [SubscriptionPlan.FREE]: 'Free',
  [SubscriptionPlan.PRO]: 'Pro',
  [SubscriptionPlan.PRO_PLUS]: 'Pro+',
} as const;

export const enum SubscriptionStatus {
  INCOMPLETE = 0, // 没开通过会员
  ACTIVE = 1, // 有效会员
  EXPIRED = 2, // 过期会员
}

export const enum BenefitBelong {
  DF = 'df',
  AI_MAKE = 'aimake',
}

// 订阅失败提示
export const SubscribeFailedTip = {
  title: 'Subscription failed',
  message: 'Payment failed. Please try again.',
};
// 订阅支付超时提示
export const SubscribeTimeoutTip = {
  message: 'Subscription result query failed. Please check in [My Space-Subscription] later.',
};

// 套餐升级失败提示
export const UpgradeFailedTip = {
  title: 'Upgrade failed',
  message: 'Payment failed. Please try again.',
};
// 套餐升级支付超时提示
export const UpgradeTimeoutTip = {
  message: 'Upgrade result query failed. Please check in [My Space-Subscription] later.',
};
