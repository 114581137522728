import { request, REQUEST_SEVER } from '@/utils/request';

// 获取用户信息，积分，是否绑定shopify等
export const getUserInfoByDtc = defineDtcApi({
  url: '/xtool-creators-club/v1/community/user/info',
  method: HttpMethod.GET,
});

// 获取积分奖品列表
export const getRewardListApi = (params?: { store: string }) => {
  return request[REQUEST_SEVER.DTC]({
    url: '/xtool-creators-club/v1/community/point_products',
    method: 'GET',
    params,
  });
};
// 绑定shopify账号
export const bindShopifyAccount = (data: { store: string }) => {
  return request[REQUEST_SEVER.DTC]({
    url: '/xtool-creators-club/v1/community/user/bind',
    method: 'POST',
    data,
  });
};

// 用户积分数据
export const getUserPointApi = () => {
  return request[REQUEST_SEVER.DTC]({
    url: `/xtool-creators-club/v1/community/user/points`,
    method: 'GET',
  });
};

// 用户积分历史
export const getUserPointHistoryApi = (params: { current: number; page_size: number; starts_at: number; ends_at: number }) => {
  return request[REQUEST_SEVER.DTC]({
    url: `/xtool-creators-club/v1/community/user/actions`,
    method: 'GET',
    params,
  });
};

// 获取积分商品详情
export const getPointProductDetail = (id: number) => {
  return request[REQUEST_SEVER.DTC]({
    url: `/xtool-creators-club/v1/community/point_product/${id}`,
    method: 'GET',
  });
};

// 兑换积分商品
export const redeemPointProduct = (id: number, data: { contact_email: string; country: string }) => {
  return request[REQUEST_SEVER.DTC]({
    url: `/xtool-creators-club/v1/community/point_product/${id}/redeem`,
    method: 'POST',
    data,
  });
};

// 订单详情
export const getOrderDetailApi = (id: number) => {
  return request[REQUEST_SEVER.DTC]({
    url: `/xtool-creators-club/v1/community/point_order/${id}`,
    method: 'GET',
  });
};

// 获取开通了会员体系的店铺列表
export const getShopStoreList = () => {
  return request[REQUEST_SEVER.DTC]({
    url: '/xtool-creators-club/v1/community/store',
    method: 'GET',
  });
};
