<template>
  <div v-if="recommendList.length" class="collection-content">
    <div
      class="m-auto relative flex px-40 pad:px-20"
      :style="{
        maxWidth: 'var(--maxBoxWidth)',
      }"
    >
      <div ref="wrapperRef" class="collection-wrapper flex w-full overflow-auto">
        <div
          v-for="(item, index) in recommendList"
          :key="item.id"
          class="flex-shrink-0"
          :style="{
            width: 'var(--colBoxWidth)',
          }"
        >
          <!-- 单个合集 -->
          <div
            class="w-full h-full inline-flex cursor-default"
            :style="{
              paddingTop: 'var(--pt)',
            }"
          >
            <NuxtLink
              ref="itemRefs"
              :to="item.targetLink"
              :target="item.target"
              class="col-item-wrapper w-full h-full flex items-center justify-center"
              :data-index="index === 0 ? 'first-el' : index === recommendList.length - 1 ? 'last-el' : null"
              @click="onColItemClick(item)"
            >
              <div class="col-item inline-flex flex-col items-center justify-center gap-5 cursor-pointer">
                <div
                  class="col-item-img border-[4px] border-solid border-[#fff] rounded-full"
                  :style="{
                    width: 'var(--colImgWidth)',
                    height: 'var(--colImgWidth)',
                  }"
                >
                  <img class="w-full h-full object-cover rounded-full" :src="item.pcImage" alt="" />
                </div>
                <div class="col-item-title text-text-secondary text-level-4 font-sans-semibold">{{ item.copyWriting }}</div>
              </div>
            </NuxtLink>
          </div>
        </div>
      </div>
      <ClientOnly>
        <icon-font
          v-if="isLeftArrowShow"
          :class="'arrow absolute z-[1] left-[80px] pad:left-[40px] w-12 h-12 bg-[#fff] rounded-full text-[26px] justify-center cursor-pointer translate-x-[-50%] translate-y-[-50%] select-none'"
          :style="{
            top: 'calc(var(--pt) + var(--colImgWidth) / 2)',
          }"
          type="icon-icon-right-m"
          @click="onArrowClick(-1)"
        />
      </ClientOnly>
      <ClientOnly>
        <icon-font
          v-if="isRightArrowShow"
          :class="'arrow absolute z-[1] right-[80px] pad:right-[40px] w-12 h-12 bg-[#fff] rounded-full text-[26px] justify-center cursor-pointer translate-x-[50%] translate-y-[-50%] select-none'"
          :style="{
            top: 'calc(var(--pt) + var(--colImgWidth) / 2)',
          }"
          type="icon-a-icon-right-m1"
          @click="onArrowClick(1)"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCollection, useCommon } from './hooks';

const { getRecommendList, recommendList, onColItemClick } = useCollection();
const { wrapperRef, itemRefs, isLeftArrowShow, isRightArrowShow, onArrowClick } = useCommon({
  firstElDataSet: 'first-el',
  lastElDataSet: 'last-el',
  step: 4,
});

await getRecommendList();

// 激活时重新获取数据
onActivated(() => {
  getRecommendList();
});
</script>

<style lang="less" scoped>
.collection-content {
  --pt: 48px;
  --colImgWidth: 112px;
}

.collection-wrapper {
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .col-item-wrapper {
    .col-item-img {
      transition-property: filter;
      transition-duration: 0.2s;
    }
    .col-item {
      transition-property: transform;
      transition-duration: 0.2s;
    }
    &:hover {
      .col-item-img {
        filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
      }
      .col-item {
        transform: translateY(-4px);
      }
    }
  }
}
.arrow {
  transition: filter 0.2s;
}
.arrow:hover {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
}
</style>
