import type { ItemType } from '@/types';
import type { Ref } from 'vue';

const StatusMap: any = {
  underReview: 'Under Review',
  rejected: 'Rejected',
  removed: 'Suspended',
  '': '',
};
const auditArr = ['underReview', 'removed', 'rejected']; // 审核状态

/**
 * 解析要跳转的目标链接，如果是在自己个人中心 projects 页面，
 * 付费案例 && 审核中的，
 * 付费案例需要跳转到审核页
 */
export function useLinkParse(makingItemRef: Ref<ItemType>) {
  const route = useRoute();
  // 是否在个人中心
  const isUserCenter = computed(() => route.fullPath === '/user-center?active=projects');

  const isForSale = computed(() => {
    return makingItemRef.value.forSale;
  });

  const isOffline = computed(() => auditArr.includes(makingItemRef.value.hintLabel));

  // 是否具备审核状态
  const isAudit = computed(() => {
    // 付费案例-则判断审核状态
    if (isUserCenter.value && isForSale.value) {
      return isOffline.value;
    }
    // 非付费案例-不显示状态
    return false;
  });

  // 审核状态
  const auditState = computed(() => {
    // 付费案例-则判断审核状态
    if (isUserCenter.value && isForSale.value) {
      if (isOffline.value) {
        return StatusMap[makingItemRef.value.hintLabel];
      }
    }
    // 非付费案例-不显示状态
    return '';
  });

  // 跳转链接
  const targetLink = computed(() => {
    // 付费案例-则判断条转链接
    if (isUserCenter.value && isForSale.value) {
      if (isOffline.value) {
        return `/audit/${makingItemRef.value.type}/${makingItemRef.value.id}`;
      } else {
        return `/${makingItemRef.value.type}/${makingItemRef.value.id}`;
      }
    }
    // 非付费案例-直接跳转详情页
    return `/${makingItemRef.value.type}/${makingItemRef.value.id}`;
  });

  return {
    isUserCenter,
    isAudit,
    targetLink,
    auditState,
  };
}
