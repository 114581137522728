import { getRecommendListApi } from '@/api/explore';

export function initCollectionData() {
  // 获取推荐列表
  const recommendList = ref<any[]>([]);
  const getRecommendList = async () => {
    try {
      const [data] = await getRecommendListApi.useFetch();
      const list = data.value ?? [];
      list.forEach((item: any) => {
        switch (item.type) {
          case 'tag':
            item.targetLink = `/all?tagGroups=${item.objectId}`;
            item.target = '_self';
            break;
          case 'collection':
            item.targetLink = `/collection/${item.objectId}`;
            item.target = '_self';
            break;
          default:
            item.targetLink = item.link;
            item.target = '_blank';
            break;
        }
      });
      recommendList.value = list;
    } catch (err) {
      console.log('err :>> ', err);
    }
  };

  return {
    getRecommendList,
    recommendList,
  };
}

export function initCollectionMethods() {
  const { sensorsHomepagecollectionClick } = useSensors();
  const onColItemClick = (item: any) => {
    const data = {
      element_type: item.type,
      element_id: item.id,
      element_name: item.copyWriting,
    };
    sensorsHomepagecollectionClick(data);
  };
  return {
    onColItemClick,
  };
}

export function useCollection() {
  return {
    ...initCollectionData(),
    ...initCollectionMethods(),
  };
}
