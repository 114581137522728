<template>
  <div class="author-user-img group/author w-full" :title="userName" @click.stop="handleClick">
    <div class="author-img">
      <img v-lazy="getOssImage" class="rounded-full" :style="{ '--s': imgSize }" alt="" />
    </div>
    <span class="user-name one-line-ellipsis font-sans text-level-5 text-text-tertiary group-hover/author:text-uni-1" :title="userName">
      {{ userName }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useUserInfo } from '@/stores/user';

type PropsType = {
  userId: number;
  userImg: string;
  userName?: string;
  imgSize?: string;
  clickable?: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  userName: '',
  imgSize: '24px',
  clickable: true,
});

const useUserInfoStore = useUserInfo();

const getOssImage = computed(() => {
  const { userImg, imgSize } = props;
  return getOssProcessedImage(
    userImg.split('?')[0],
    `m_fill,w_${Number(imgSize.replace('px', '')) * 2},h_${Number(imgSize.replace('px', '')) * 2}`,
    'webp',
  );
});

const handleClick = (e: Event) => {
  e.stopPropagation();
  e.preventDefault();

  if (!props.clickable) {
    return;
  }

  if (props.userId === useUserInfoStore.myInfo.id) {
    navigateTo({
      path: '/user-center',
      query: {
        active: 'projects',
      },
    });
  } else {
    navigateTo(`/profile/${props.userId}`);
  }
};
</script>

<style lang="less">
.author-user-img {
  display: flex;
  align-items: center;
  cursor: pointer;
  .user-name {
    margin-left: 8px;
    max-width: 80%;
    word-break: break-all;
  }
  .author-img {
    display: block;
    font-size: 0;
    img {
      width: var(--s, 20px);
      height: var(--s, 20px);
    }
  }
  .xs-author-img {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .author-user-img {
    .user-name {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-left: 4px;
    }
    .author-img {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
