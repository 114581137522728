import { Methods, request, REQUEST_SEVER } from '@/utils/request';
// 获取合集内容详情
export const getCollectionDetailApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/collection/${id}`,
    method: Methods.GET,
  });
};

export type collectionListSorteType = 'latest' | 'hottest' | 'view' | 'like';

// 获取合集的作品列表
export const getCollectionListApi = (
  id: number,
  params: { current: number; pageSize: number },
  sorter: collectionListSorteType,
) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/collection/${id}/items`,
    method: Methods.GET,
    params: {
      ...params,
      sorter,
    },
  });
};
