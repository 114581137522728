import { CONTENT_TYPE } from '@/types';

export enum ColumnTypeEnum {
  Creations = 'creations',
  Premium = 'prime',
}

export const ColumnMap = {
  [ColumnTypeEnum.Creations]: {
    title: 'Community Shared Assets',
    key: ColumnTypeEnum.Creations,
  },
  [ColumnTypeEnum.Premium]: {
    title: 'Premium Assets',
    key: ColumnTypeEnum.Premium,
  },
};

export const ContentMap = {
  [CONTENT_TYPE.HOWTOS]: {
    title: 'How-tos',
    key: CONTENT_TYPE.HOWTOS,
  },
  [CONTENT_TYPE.INSPIRATION]: {
    title: 'Templates',
    key: CONTENT_TYPE.INSPIRATION,
  },
  [CONTENT_TYPE.DESIGNS]: {
    title: 'Designs',
    key: CONTENT_TYPE.DESIGNS,
  },
};
