<template>
  <div class="block-card-item-wrapper h-full w-full">
    <a class="block-card-item group/block-card flex h-full w-full rounded-xl bg-[#fff] px-2 pb-4 pt-2" :href="targetLink" @click.prevent>
      <div
        id="blockSingle"
        :key="makingItem.id"
        class="group/single-item relative h-full w-full"
        :class="{ 'block-single-item': !onlyImg }"
        :data-sensors-exposure-event-name="SENSORS_EVENT?.CONTENT_EXPOSURE"
        data-sensors-exposure-config-area_rate="0.5"
        data-sensors-exposure-config-stay_duration="2"
        data-sensors-exposure-config-repeated="true"
        :data-sensors-exposure-property-content_id="makingItem.id"
        :data-sensors-exposure-property-content_name="makingItem.title"
        :data-sensors-exposure-property-content_show_type="makingItem.type"
        :data-sensors-exposure-property-content_tag="(makingItem as any)?.[tagsField] || []"
        :data-sensors-exposure-property-publish_time="makingItem.createdAt"
        :data-sensors-exposure-property-author_name="makingItem.createdByName"
        :data-sensors-exposure-property-show_source="locationHref"
        :data-sensors-exposure-property-block_id="blockId"
        :data-sensors-exposure-property-block_name="blockTitle"
        :data-sensors-exposure-property-service_type="makingItem.type"
        :data-sensors-exposure-property-content_type="makingItem.forSale ? 'paid' : 'free'"
        :data-sensors-exposure-property-algorithm_type="makingItem.recommend?.strategy ?? null"
        :data-sensors-exposure-property-algorithm_version="makingItem.recommend?.version ?? null"
        @click="handleCheckMakings(makingItem)"
      >
        <!-- 右上角勾勾 -->
        <template v-if="postCheck">
          <div class="project-radio-icon absolute right-0.5 top-0.5 z-[11] inline-flex">
            <img width="24" height="24" :src="makingItem?.checked ? checkRadio : uncheckRadio" :alt="makingItem.title" />
          </div>
        </template>

        <!-- 右上角操作项 -->
        <EclipseAction
          v-if="actions.length > 0"
          :actions="actions"
          class="absolute right-1 top-1 z-[11]"
          type="block"
          @toggle-actions="handleToggleActions"
        />

        <template v-if="!onlyImg">
          <div class="block-top-wrap group/cover relative w-full" :title="makingItem.title">
            <!-- image -->
            <RatioBox :padding-top="'75%'">
              <div class="img-wrapper h-full w-full overflow-hidden rounded-[8px]">
                <template v-if="checkImg(makingItem?.extra?.blockCover || makingItem.cover, '.gif')">
                  <img
                    :key="makingItem.id"
                    v-lazy="{
                      src: getOssProcessedImage(makingItem?.extra?.blockCover || makingItem.cover, 'm_fill,w_800,h_600', 'webp'),
                      disabled: disabledLazyLoad,
                    }"
                    :class="'lazyimg cover-img flex h-full w-full rounded-[8px] object-cover group-hover/single-item:hidden'"
                    :alt="makingItem.title"
                  />
                  <img
                    :key="makingItem.id"
                    v-lazy="{
                      src: getOssProcessedImage(makingItem?.extra?.blockCover || makingItem.cover, '', 'webp'),
                      disabled: disabledLazyLoad,
                    }"
                    :class="'lazyimg cover-img hidden h-full w-full rounded-[8px] object-cover group-hover/single-item:flex'"
                    :alt="makingItem.title"
                  />
                </template>
                <template v-else>
                  <img
                    :key="makingItem.id"
                    v-lazy="{
                      src: getOssProcessedImage(makingItem?.extra?.blockCover || makingItem.cover, 'm_fill,w_800,h_600', 'webp'),
                      disabled: disabledLazyLoad,
                    }"
                    class="lazyimg cover-img h-full w-full rounded-[8px] object-cover"
                    :alt="makingItem.title"
                  />
                </template>
              </div>
            </RatioBox>
            <div class="absolute left-0 top-0 z-[10] flex w-full justify-between gap-1">
              <!-- 付费tag -->
              <div
                v-if="isForSale && sellTagInfo?.tagName && feedbackStatus === 'deactive'"
                :class="'seller-tag mt-2 flex h-6 -translate-x-2 items-center justify-center px-3 font-sans-semibold text-level-4 group-hover/block-card:hidden'"
                :style="{
                  'background': sellTagInfo.tagBgColor,
                  '--tagAngleColor': sellTagInfo.tagAngleColor,
                }"
              >
                <span class="italic text-[#fff]">{{ sellTagInfo.tagName }}</span>
              </div>
              <!-- 左侧标签 -->
              <div class="block-card-left-extra inline-flex overflow-hidden">
                <slot name="leftExtra" :card-info="makingItem">
                  <div class="left-extra ml-2 mt-2 inline-flex gap-2 opacity-0 group-hover/block-card:opacity-100">
                    <div
                      v-if="deviceTag"
                      :class="'device-tag inline-flex h-6 items-center justify-center rounded-[29px] bg-[#1A1A1A] px-3 py-1 text-xs font-semibold text-[#fff] opacity-80 backdrop-blur-[15px]'"
                      :title="deviceTag"
                    >
                      {{ deviceTag }}
                    </div>
                    <div
                      v-if="materialsTag"
                      :class="'materials-tag inline-flex h-6 items-center justify-center rounded-[29px] bg-[#1A1A1A] px-3 py-1 text-xs font-semibold text-[#fff] opacity-80 backdrop-blur-[15px]'"
                      :title="materialsTag"
                    >
                      {{ materialsTag }}
                    </div>
                  </div>
                </slot>
              </div>
              <!-- 右侧类型 -->
              <div v-if="!postCheck && actions.length === 0" class="block-card-right-extra inline-flex">
                <slot name="rightExtra" :card-info="makingItem">
                  <div
                    :class="'right-extra relative mr-2 mt-2 inline-flex h-6 gap-0.5 rounded-full bg-[rgba(244,244,244,0.90)]'"
                    @click.prevent="onTypeClick"
                  >
                    <ClientOnly>
                      <div class="flex items-center justify-center px-1 group-hover/block-card:hidden">
                        <icon-font
                          v-if="MakingTypeMap[makingItem.type]?.icon"
                          :type="MakingTypeMap[makingItem.type]?.icon"
                          class="text-base text-text-primary"
                        />
                      </div>
                    </ClientOnly>
                    <div
                      class="hidden items-center justify-center gap-0.5 pl-2 pr-1 group-hover/block-card:flex"
                      :class="[
                        {
                          'pr-2': disabledRightExtra,
                        },
                      ]"
                    >
                      <ClientOnly>
                        <icon-font
                          v-if="MakingTypeMap[makingItem.type]?.icon"
                          :type="MakingTypeMap[makingItem.type]?.icon"
                          class="text-base text-text-primary"
                        />
                      </ClientOnly>
                      <div class="flex h-full w-full items-center text-text-primary">
                        <span class="card-type font-sans-semibold text-level-7 leading-4">{{ MakingTypeMap[makingItem.type]?.name }}</span>
                        <ClientOnly v-if="!disabledRightExtra">
                          <icon-font class="text-[16px]" type="icon-a-icon-right-m1" />
                        </ClientOnly>
                      </div>
                    </div>
                  </div>
                </slot>
              </div>
            </div>
            <!-- With instruction 标识 -->
            <ClientOnly>
              <a-tooltip v-if="makingItem.hasInstruction" placement="top" :align="{ offset: [0, 5] }">
                <template #title>With instruction</template>
                <div
                  :class="[
                    `absolute bottom-0 left-0 z-20`,
                    `h-8 w-8 rounded-bl-lg rounded-tr-lg`,
                    `hidden items-center justify-center bg-text-primary group-hover/block-card:flex`,
                    `opacity-80 backdrop-blur-[15px]`,
                  ]"
                >
                  <icon-font type="icon-a-Withinstruction" class="text-[20px] text-[#FFF]" />
                </div>
              </a-tooltip>
            </ClientOnly>

            <!-- 价格 -->
            <template v-if="isForSale">
              <BenefitPrice
                v-if="isPgcOrUgc"
                :item="makingItem"
                :price-info="priceInfo"
                class="absolute bottom-0 right-0 z-20 flex h-7 overflow-hidden rounded-br-lg rounded-tl-lg"
              />
              <div v-else class="absolute bottom-0 right-0 z-20 flex h-7 overflow-hidden rounded-br-lg rounded-tl-lg leading-7">
                <div v-if="isDiscount" class="flex items-center justify-center bg-[#FF7C23] px-2">
                  <span class="font-sans-bold text-level-4 text-[#fff]">
                    {{ priceInfo.discountPercent }}
                  </span>
                </div>
                <div
                  class="inline-flex h-7 items-center justify-center gap-1 px-2 backdrop-blur-[15px]"
                  :class="{
                    'bg-uni-0 text-[#fff]': isDiscount,
                    'bg-uni-11/80 font-sans-semibold text-level-4 text-text-primary': !isDiscount,
                  }"
                  :style="{
                    'border-top-left-radius': isDiscount ? '0' : '8px',
                  }"
                >
                  <div class="font-sans-bold text-level-4">
                    {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
                  </div>
                  <div v-if="isDiscount" class="text-xs text-[#fff] line-through opacity-40">{{ priceInfo.origPrice }}</div>
                </div>
              </div>
            </template>
          </div>

          <!-- title -->
          <div class="mt-2 flex items-center justify-between px-2">
            <h5
              :class="[`mb-0 h-[22px] w-[calc(100%-30px)]`, `font-sans-semibold text-level-3 text-text-primary`, `one-line-ellipsis`]"
              :title="makingItem.title"
            >
              {{ makingItem.title }}
            </h5>
            <ClientOnly v-if="!disabledMoreOption">
              <MoreOpBtn @command="onMoreOpt" />
            </ClientOnly>
          </div>

          <!-- user -->
          <div class="user-wrap block-bottom-wrap mt-2 flex h-6 items-center justify-between px-2" @click.prevent="">
            <author-user-img
              v-if="isShowAuthor"
              class="max-w-[60%]"
              size="24px"
              img-size="24px"
              :user-id="makingItem.createdBy"
              :user-img="makingItem.createdByHeadpic"
              :user-name="makingItem.createdByName"
              :block-id="blockId"
              :block-name="blockTitle"
              :is-show-user-card="isShowUserCard"
              @click.prevent=""
            />
            <div v-else class="flex gap-[8px]">
              <ClientOnly>
                <div class="one-line-ellipsis h-[22px] text-sm text-text-primary">
                  {{ moment(makingItem?.publishedAt * 1000).format('MMM DD,YYYY') }}
                </div>
              </ClientOnly>
              <span
                v-if="isAudit"
                :class="[
                  'flex items-center rounded border-[1px] border-solid pl-[6px] pr-[6px] text-[12px]',
                  {
                    'border-[#ffd1ab] bg-[#FFEAD6] text-[#FF7000]': BlockCardStatus.Todo === auditState,
                    'border-[#ffb6b7] bg-[#FFE2E2] text-[#FF4D4F]': BlockCardStatus.Rejected === auditState,
                    'border-[#c8c8c8] bg-[#EDEDED] text-[#999]': BlockCardStatus.Removed === auditState,
                  },
                ]"
              >
                {{ auditState }}
              </span>
            </div>
            <div class="flex gap-2">
              <div
                v-for="(item, index) in iconNumList"
                :key="index"
                class="flex items-center gap-0.5 text-text-primary"
                :title="item.type"
                @click.prevent="postCommonActions($event, item.type)"
              >
                <ClientOnly>
                  <icon-font
                    :type="item.icon"
                    class="text-base"
                    :class="[
                      {
                        'text-text-primary hover:text-[#ff0035]': item.type === 'like' && !item.isTriggled,
                        'text-[#ff0035]': item.type === 'like' && item.isTriggled,
                      },
                    ]"
                  />
                </ClientOnly>
                <ClientOnly>
                  <span class="whitespace-nowrap font-sans-semibold text-level-7">{{ item.count }}</span>
                </ClientOnly>
              </div>
            </div>
          </div>
        </template>
        <!-- 纯图片模式 -->
        <template v-else>
          <img
            v-lazy="getOssProcessedImage(makingItem.cover, 'm_mfit,w_600,h_450', 'webp')"
            class="only-img rounded-xl"
            :title="makingItem.title"
            :alt="makingItem.title"
          />
        </template>
        <Feedback v-model:status="feedbackStatus" :item="makingItem" />
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import checkRadio from '@/assets/img/check-radio.png';
import uncheckRadio from '@/assets/img/uncheck-radio.png';
import { BlockCardStatus, type ItemType, MakingAttr, MakingTypeMap, type OBJECT_TYPE, SENSORS_EVENT } from '@/types';
import moment from 'moment';
import { downloadZip } from '~/utils/fileZip';
import { useLinkParse } from '../hook';
import Feedback from './Feedback.vue';
import MoreOpBtn from './MoreOpBtn.vue';
import BenefitPrice from './BenefitPrice.vue';
import { deviceTagComputed, iconNumListComputed, materialsTagComputed, priceComputed, tagComputed, useBlockCard } from './hooks';

type PropsType = {
  makingItem: ItemType;
  actions?: string[];
  postCheck?: boolean; // 是否展示复选框，层级最高
  isShowAuthor?: boolean; // 是否展示作者
  isDownload?: boolean; // 是否可以下载
  isSearch?: boolean; // 是否为搜索结果
  disabledLazyLoad?: boolean; // 禁用封面的懒加载，活动页运营要求
  onlyImg?: boolean; // 只展示图片
  blockTitle?: string; // 栏目名称
  blockId?: number; // 栏目id
  linkTarget?: '_self' | '_blank'; // 链接打开方式
  isUserCenter?: boolean; // 是否在用户中心
  disabledRightExtra?: boolean; // 是否禁用点击右侧类型
  disabledMoreOption?: boolean; // 是否禁用更多操作
  isShowUserCard?: boolean; // 是否展示用户卡片
  tagsField?: string; // 标签字段
};
type EmitsType = {
  (e: 'toggleActions', type: string): void;
  (e: 'radioCheck', item: ItemType): void;
  (e: 'clickItem'): void; // for search result page
  (e: 'emitSensors', type: string): void;
};

const emits = defineEmits<EmitsType>();
const props = withDefaults(defineProps<PropsType>(), {
  isShowAuthor: true,
  onlyImg: false,
  isSearch: false,
  actions: () => [],
  blockTitle: '',
  blockId: undefined,
  linkTarget: '_self',
  isUserCenter: false,
  disabledMoreOption: true,
  isShowUserCard: false,
  tagsField: 'contentTags',
});
const { makingItem: makingItemRef } = toRefs(props);
const { isAudit, targetLink, auditState } = useLinkParse(makingItemRef);

const { sensorContentClickEvent, sensorForYouSeeFewerClick } = useSensors();
const { onTypeClick } = useBlockCard({
  makingItem: props.makingItem,
  disabledRightExtra: props.disabledRightExtra,
});

const ITEM_TYPE = ref(props.makingItem.type);

// 数据统计
const iconNumList = computed(() => iconNumListComputed(props.makingItem));
// 是否付费
const isForSale = computed(() => props.makingItem.forSale);
// 是否有折扣
const isDiscount = computed(() => props.makingItem.hasDiscount);
// 折扣信息
const priceInfo = computed(() => priceComputed(props.makingItem));
// 付费标签
const sellTagInfo = computed(() => tagComputed(props.makingItem, props.isUserCenter));
// 材料tag
const materialsTag = computed(() => materialsTagComputed(props.makingItem));
// 机型tag
const deviceTag = computed(() => deviceTagComputed(props.makingItem));

const isPgcOrUgc = computed(() => [MakingAttr.PGC, MakingAttr.UGC].includes(props.makingItem.subscriptionAttr as any));

const handleToggleActions = (type: string) => {
  emits('toggleActions', type);
};

// 调用公共的操作方法
const postCommonActions = (e: any, actionType: string) => {
  e.stopPropagation();
  // like
  if (actionType === 'like') {
    const hasCookie = !!useMiddleCookie('utoken')?.value;
    handleToggleAction('making' as OBJECT_TYPE, props.makingItem, actionType, 'block', hasCookie);
  }

  // download
  if (actionType === 'download') {
    if (props.isDownload && props.makingItem.attachFiles.length > 0) {
      emits('emitSensors', ITEM_TYPE.value);
      const fileList = props.makingItem.attachFiles;
      downloadZip(fileList, 'files');
    }
  }
};

const router = useRouter();
const route = useRoute();
// 点击卡片
const handleCheckMakings = (item: ItemType) => {
  // 有复选框的时候不跳转不弹窗, 优先级最高
  if (props.postCheck) {
    emits('radioCheck', item);
    return;
  }

  const { makingItem } = props;
  // 内容点击上报
  sensorContentClickEvent({
    content_id: makingItem.id,
    content_name: makingItem.title,
    service_type: ITEM_TYPE.value,
    publish_time: makingItem.createdAt,
    content_tag: makingItem?.contentTags || [],
    author_name: makingItem.createdByName,
    author_id: makingItem.createdBy,
    link_url: `${window.location.origin}/${ITEM_TYPE.value}/${makingItem.id}`,
    block_name: props.blockTitle,
    block_id: props.blockId,
    content_type: makingItem.forSale ? 'paid' : 'free',
    algorithm_type: makingItem.recommend?.strategy,
    algorithm_version: makingItem.recommend?.version,
  });

  // 搜索的结果点击上报
  if (props.isSearch) {
    emits('clickItem');
  }

  if (isAudit.value || props.linkTarget === '_blank') {
    window.open(targetLink.value, '_blank');
  } else {
    router.push({
      path: targetLink.value,
      query: {
        ...route.query,
      },
    });
  }
};

const locationHref = ref();
onMounted(() => {
  locationHref.value = window?.location?.pathname;
});

const onMoreOpt = (cmd: string) => {
  if (cmd === 'feedback') {
    feedbackStatus.value = 'selecting';

    const item = props.makingItem;
    sensorForYouSeeFewerClick({
      content_id: item.id + '',
      content_type: item.forSale ? 'paid' : 'free',
      content_name: item.title,
      service_type: item.type,
      element_name: 'See fewer assets like this',
    });
  }
};

const feedbackStatus = ref<any>('deactive');
watch(
  () => feedbackStatus.value,
  () => {
    console.log('feedbackStatus.value', feedbackStatus.value);
  },
);
</script>

<style lang="less" scoped>
.block-card-item {
  transition-property: box-shadow, transform;
  transition-duration: 0.2s;
  &:hover {
    .img-wrapper::before {
      display: block;
    }
  }
}
.block-card-item-wrapper:hover {
  .block-card-item {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
  }
}

.img-wrapper {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    //border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 8px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: none;
  }

  .cover-img {
    transition-property: transform;
    transition-duration: 0.5s;
  }
}

.only-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.seller-tag {
  position: absolute;
  border-radius: 4px 12px 12px 0;
  z-index: 10;
  &::before {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: '';
    border: 4px solid var(--tagAngleColor);
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
}

.block-card-left-extra {
  flex: 1;
  position: relative;
  .left-extra {
    transition-property: opacity;
    transition-duration: 0.5s;
    .device-tag {
      white-space: nowrap;
    }
    .materials-tag {
      white-space: nowrap;
    }
  }
}
.right-extra {
  backdrop-filter: blur(7.5px);
  .card-type {
    white-space: nowrap;
  }
}
</style>
