import { isObject, isNumber } from 'lodash-es';

type Cb = (ticker: Ticker) => void;

interface Ticker {
  /**
   * @description 是否激活
   */
  readonly isActive: boolean;
  /**
   * @description 总的周期数，reset 后不会重置
   */
  readonly tickCount: number;
  /**
   * @description ticker 启动后经历的周期数，reset 后会重置
   */
  readonly delta: number;
  /**
   * @description 暂停 ticker
   */
  pause(): void;
  /**
   * @description 激活暂停的 ticker
   */
  reuse(): void;
  /**
   * @description 重置 ticker 状态
   */
  reset(opts?: TickerOptions): void;
}

interface TickerOptions {
  /**
   * @description 周期间隔时间，单位毫秒
   */
  ms: number;
  /**
   * @description 是否自动激活
   */
  auto?: boolean;
  /**
   * @description 是否初始化的时候执行回调
   */
  immediate?: boolean;
}

/**
 * @description 使用滴答器周期执行回调函数
 * @param callback 回调函数
 * @param ms 周期间隔时间，单位毫秒，默认为 0
 */
export function useTicker(callback: Cb, ms?: number): Ticker;
/**
 * @description 使用滴答器周期执行回调函数
 * @param callback 回调函数
 * @param opts ticker 配置
 */
export function useTicker(callback: Cb, opts?: TickerOptions): Ticker;
export function useTicker(callback: Cb, opts?: TickerOptions | number): Ticker {
  const _opts = call(() => {
    const _opts: TickerOptions = {
      ms: 0,
      auto: true,
      immediate: false,
    };
    if (isObject(opts)) {
      Object.assign(_opts, opts);
    } else if (isNumber(opts)) {
      _opts.ms = opts;
    }
    return _opts;
  });

  let timer: number;
  let previousTickCount = 0;
  const delta = ref(0);
  const tickCount = ref(0);
  const isActive = ref(false);
  const loop = () => {
    if (!isActive.value) {
      return;
    }

    if (_opts.immediate) {
      tryCall(() => callback(ticker));
    }

    timer = setTimeout(() => {
      if (!isActive.value) {
        return;
      }

      tickCount.value = tickCount.value + 1;
      delta.value = tickCount.value - previousTickCount;
      if (!_opts.immediate) {
        tryCall(() => callback(ticker));
      }
      loop();
    }, _opts.ms) as unknown as number;
  };
  const pause = () => {
    isActive.value = false;
    if (isNumber(timer)) {
      clearTimeout(timer);
    }
  };
  const reuse = () => {
    if (isActive.value) {
      pause();
    }
    isActive.value = true;
    loop();
  };
  const reset = (opts?: TickerOptions) => {
    previousTickCount = tickCount.value;
    if (isObject(opts)) {
      Object.assign(_opts, opts);
    }
    reuse();
  };

  const ticker = {
    pause,
    reuse,
    reset,
    get isActive() {
      return isActive.value;
    },
    get tickCount() {
      return tickCount.value;
    },
    get delta() {
      return delta.value;
    },
  };

  if (_opts.auto) {
    onMounted(reuse);
  }
  tryOnUnMounted(pause);
  return ticker;
}
