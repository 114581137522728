import { getUserRoleApi } from '@/api/common';
import { getMyInfoApi, getWhitesStatus } from '@/api/user';
import { defineStore } from 'pinia';
import { getShopStoreList, getUserInfoByDtc } from '~/api/rewards';
import type { WhitesType } from './types';
import { RESPONSE_CODE } from './types';

export const useUserInfo = defineStore('userInfo', {
  state: () => {
    return {
      isAdmin: false,
      isArtimindUat: false,
      myInfo: {
        headpic: '',
        id: 0,
        uid: 0,
        name: '',
        signature: '',
        followingCount: 0,
        followersCount: 0,
        makingCount: 0,
        email: '',
        createTime: 0,
      },
      shopifyUserInfo: {
        email: '',
        points: 0,
        is_bind: false,
      },
      showBackTop: true,
      userStoreList: [] as any[],
      userBindCountry: '' as string | undefined,
      isSaleMaker: false,
      isXthingsMaker: false,
      isBomMaker: undefined as boolean | undefined,
      initialBindModal: false,
      whites: undefined as WhitesType | undefined,
    };
  },
  getters: {
    selectCountryOpts(state) {
      return state.userStoreList.map((item) => {
        return {
          ...item,
          value: item.main_country_code,
          label: item.main_country_code,
          icon: item.main_country_code_icon_url,
          fullName: item.main_country,
          store: item.store,
        };
      });
    },
  },
  actions: {
    async init() {
      await Promise.all([this.updateUserRole(), this.updateWhitesStatus(), this.updateMyInfo(), this.updateShopifyUserInfo()]);
    },
    // 更新shopify用户关联信息
    async updateShopifyUserInfo() {
      try {
        const res = await getUserInfoByDtc.fetch();
        if (res?.code === 0) {
          this.shopifyUserInfo = res.data;
          this.initialBindModal = !this.shopifyUserInfo.is_bind;
        }
      } catch (error) {
        console.warn(error);
      }
    },
    async updateMyInfo() {
      try {
        const res = await getMyInfoApi.fetch();
        if (res?.code === RESPONSE_CODE.SUCCESS) {
          Object.assign(this.myInfo, res.data);
        } else {
          throw new Error(res?.message ?? '用户信息获取失败');
        }
      } catch (err) {
        console.log('update-my-info-err :>> ', err);
      }
    },
    async updateUserRole() {
      try {
        const res = await getUserRoleApi.fetch({ d: 'xtool' });
        if (res?.code === RESPONSE_CODE.SUCCESS) {
          const roleList = res.data ?? [];
          this.isAdmin = roleList.some((item: any) => item.code === 'community_admin');
          this.isArtimindUat = roleList.some((item: any) => item.code === 'artimind_uat');
        } else {
          throw new Error(res?.message ?? '用户信息更新失败');
        }
      } catch (err) {
        console.log('updateUserRole-err :>> ', err);
      }
    },
    updateShowBackTop(bool: boolean) {
      this.showBackTop = bool;
    },
    resetUserInfo() {
      this.isAdmin = false;
      this.myInfo = {
        headpic: '',
        id: 0,
        uid: 0,
        name: '',
        signature: '',
        followingCount: 0,
        followersCount: 0,
        makingCount: 0,
        email: '',
        createTime: 0,
      };
    },
    resetInitialBindModal() {
      this.initialBindModal = false;
    },
    async updateUserStoreList() {
      const { data } = await getShopStoreList();
      if (!data?.value?.data?.stores) {
        return false;
      }
      const { stores } = data.value.data;
      if (stores?.length) {
        this.userStoreList = stores;
        this.userBindCountry = this.userStoreList.filter((store) => store.is_bind).map((store) => store.main_country_code)[0];
      }
    },
    async updateWhitesStatus() {
      const res = await getWhitesStatus.fetch();
      if (res?.code === RESPONSE_CODE.SUCCESS && res.data) {
        const resData = res.data;
        this.whites = resData;
        this.isSaleMaker = resData['sale_maker'];
        this.isXthingsMaker = resData['xthings_maker'];
        this.isBomMaker = !!resData['bom_maker'];
      }
    },
  },
});
