import { isNil } from 'lodash-es';
import { getCurrentInstance, getCurrentScope, onMounted, onScopeDispose, onUnmounted } from 'vue';
import type { Fn } from '~/types/fns';

/**
 * 获取组件根标签
 * @param instance 组件实例
 * @return 组件根标签
 */
export function getComponentRootEl(instance: any) {
  let node = instance?.vnode?.el || (instance && (instance?.$el || instance));
  while (node && !node.tagName) {
    node = node.nextSibling;
  }
  return node as HTMLElement;
}

export function tryOnMounted(cb: Fn) {
  if (!isNil(getCurrentInstance())) {
    onMounted(cb);
  } else {
    cb();
  }
}

export function tryOnUnMounted(cb: Fn) {
  if (!isNil(getCurrentScope())) {
    onScopeDispose(cb);
  } else if (!isNil(getCurrentInstance())) {
    onUnmounted(cb);
  } else {
    throw new Error('must call in effectScope or setup');
  }
}
