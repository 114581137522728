<template>
  <div class="home-wrapper-xs">
    <Banner />
    <div v-if="items && items.length > 0" class="mobile-list">
      <div v-for="item in items" :key="item.id" class="pb-[20px]">
        <BlockCardItem :making-item="item" :disabled-more-option="false" />
      </div>
      <bottom-loading :loading="status.loading" :finished="status.finished" @infinite="infiniteLoad" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useForYou } from '~/components/featured/hooks';
import Banner from './banner/index.vue';

const { items, status, init, infiniteLoad, updateMakingList } = useForYou();

await init();

// 激活时重新获取数据
onActivated(() => {
  updateMakingList();
});
</script>

<style lang="less" scoped>
.home-wrapper-xs {
  @media screen and (max-width: 767px) {
    display: block;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobile-list {
  padding: 0 20px 20px;
}
:deep(.ant-list-grid) .ant-col > .ant-list-item {
  margin-bottom: 20px;
}
</style>
