import type { OBJECT_TYPE } from '@/stores/types';
import { Methods, request, REQUEST_SEVER } from '@/utils/request';
import { defineFindApi } from '~/utils/fetch/define-api';

// 查找用户
export const searchUserListApi = (params: { current: number; pageSize: number; q: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/users',
    method: Methods.GET,
    params,
  });
};

// 获取我的收藏
export const getMyFavoriteListApi = (params: {
  // current: number;
  // pageSize: number;
  type: string;
}) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/favorites',
    method: Methods.GET,
    params,
  });
};

// 获取我的创作列表
export const getMyMakingListApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/makings',
    method: Methods.GET,
    params,
  });
};

// 获取我的问题列表
export const getMyQuestionListApi = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/questions',
    method: Methods.GET,
    params,
  });
};

// 获取我的回答列表
export const getMyAnswerListApi = (params: { current: number; pageSize: number; sorter?: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/answers',
    method: Methods.GET,
    params,
  });
};

// 获取我关注的问答
export const getMyFollowListApi = (params: { current: number; pageSize: number; objectType: OBJECT_TYPE }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/followings',
    method: Methods.GET,
    params,
  });
};

// 获取我发表的讨论
export const getMyDiscussionListApi = (params: { current: number; pageSize: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/discussions',
    method: Methods.GET,
    params,
  });
};

// 获取我的回复列表
export const getMyReplyListApi = (params: { current: number; pageSize: number; sorter?: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/replies',
    method: Methods.GET,
    params,
  });
};

// 获取我的信息
export const getMyInfoApi = defineFindApi({
  url: '/community/v1/web/my/info',
  method: Methods.GET,
});

// 获取用户信息
export const getUserInfoApi = (id: number, config?: Record<string, any>) => {
  return request[REQUEST_SEVER.DEFAULT](
    {
      url: `/community/v1/web/user/${id}/info`,
      method: 'GET',
    },
    {
      ...config,
    },
  );
};

// 获取用户创作列表
export const getUserMakingListApi = (id: number, params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/user/${id}/makings`,
    method: 'GET',
    params,
  });
};
// 获取我的关注列表
export const getMyFollowingList = (
  params: {
    current: number;
    pageSize: number;
    objectType: string;
  },
  config?: any,
) => {
  return request[REQUEST_SEVER.DEFAULT](
    {
      url: '/community/v1/web/my/followings',
      method: Methods.GET,
      params,
    },
    {
      ...config,
    },
  );
};

// 获取我的粉丝列表
export const getMyFollowersList = (
  params: {
    current: number;
    pageSize: number;
  },
  config?: any,
) => {
  return request[REQUEST_SEVER.DEFAULT](
    {
      url: '/community/v1/web/my/followers',
      method: Methods.GET,
      params,
    },
    {
      ...config,
    },
  );
};
// 移除粉丝
export const removeFollower = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/follower/${id}`,
    method: Methods.DELETE,
  });
};
// 用户操作
export const doActionApi = (
  action: string,
  data: {
    objectType: OBJECT_TYPE;
    objectId: number;
    contentType?: string;
    revoke?: boolean;
  },
) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/action/${action}`,
    method: 'POST',
    data,
  });
};
// 获取我的设计文件列表
export const getDesignFileList = (params: { type: string; ids?: number[]; current?: number; pageSize?: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/files`,
    method: Methods.GET,
    params,
  });
};
// 获取文件详细参数
export const getDesignFileInfo = (params: { ids: number[] }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/file/:id`,
    method: Methods.GET,
    params,
  });
};
// 上传文件
export const postDesignFile = (data: { name: string; url: string; type: string; fileSize: number; extra: any }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/file`,
    method: Methods.POST,
    data,
  });
};
// 删除文件
export const deleteDesignFile = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/file/${id}`,
    method: Methods.DELETE,
  });
};
// 帖子最近使用-（机型&瓦数&软件&耗材）
export const getLatestUse = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/lately-use`,
    method: Methods.GET,
  });
};
// 获取白名单状态
export const getWhitesStatus = defineFindApi({
  url: '/community/v1/web/my/whites',
  method: HttpMethod.GET,
});
// 获取草稿箱数量

export const getDraftCount = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/draft/count',

    method: Methods.GET,
  });
};
