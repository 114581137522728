import { getCreatorListApi } from '@/api/explore';
import SubtractSvg from '@/assets/img/block-card/subtract.svg';

export function initCreatorsData() {
  // 获取创作者列表
  const creatorList = ref<any[]>([]);
  const getCreatorList = async () => {
    try {
      const [data] = await getCreatorListApi.useFetch({
        pageType: 'home',
      });
      const list = data.value ?? [];
      list.forEach((item: any) => {
        if (item.topMakings.length <= 0) {
          const arr = [];
          for (let i = 0; i < 2; i++) {
            arr.push({
              cover: SubtractSvg,
              noCover: true,
            });
          }
          item.topMakings = arr;
        } else if (item.topMakings.length === 1) {
          item.topMakings.push({
            cover: SubtractSvg,
            noCover: true,
          });
        } else {
          item.topMakings.forEach((m: any) => {
            if (!m.cover) {
              m.cover = SubtractSvg;
              m.noCover = true;
            }
          });
        }
      });
      creatorList.value = list;
    } catch (err) {
      console.log('err :>> ', err);
    }
  };

  return {
    getCreatorList,
    creatorList,
  };
}

export function initCreatorsMethods() {
  const handleFollowChange = (val: boolean, item: any) => {
    item.isFollowed = val;
  };

  return {
    handleFollowChange,
  };
}

export function useCreators() {
  return {
    ...initCreatorsData(),
    ...initCreatorsMethods(),
  };
}
