<template>
  <NuxtLink class="block-user-link block rounded-xl bg-[#fff]" :to="`/profile/${userItem[idKey]}`">
    <div class="block-user-item" @click="hanldeClickItem">
      <div class="lg-block-user pb-6">
        <!-- <template v-if="actions?.length">
          <eclipse-action :actions="actions" class="action-eclipse-icon" type="text" @toggle-actions="handleToggleActions" />
        </template> -->

        <div class="flex gap-0.5 overflow-hidden rounded-t-xl">
          <RatioBox v-for="(item, index) in topMakings" :key="index" :padding-top="'100%'" :width="'calc(50% - 1px)'">
            <div
              class="flex h-full w-full items-center justify-center overflow-hidden"
              :class="{
                'bg-background-2': item.noCover,
              }"
            >
              <img
                class="h-full"
                :src="getOssProcessedImage(item.cover, 'm_fill,w_200,h_200', 'webp')"
                alt=""
                :style="{
                  width: item.noCover ? '36px' : '100%',
                }"
              />
            </div>
          </RatioBox>
        </div>

        <div class="relative z-10 mt-[calc(-18%)] flex flex-col items-center">
          <RatioBox :padding-top="'100%'" :width="'36%'">
            <div class="h-full w-full overflow-hidden rounded-full border-4 border-solid border-[#fff] bg-[#fff]">
              <img
                class="h-full w-full"
                :src="userItem.deletedAt ? deletedUseAvatarImg : getOssProcessedImage(userItem.headPic.split('?')[0], `m_fill,w_200,h_200`, 'webp')"
                alt=""
              />
            </div>
            <!--            <img
              v-if="userItem.isPrime"
              :src="PRIME_CREATOR"
              alt=""
              class="absolute bottom-0 right-0 h-6 w-6"
              @click.prevent.stop="navigateTo('/creator/guide')"
            />-->
            <img v-if="userItem.isPrime" :src="PRIME_CREATOR" alt="" class="absolute bottom-0 right-0 h-6 w-6" />
          </RatioBox>

          <div class="user-name one-line-ellipsis mt-4 h-[22px] w-full px-1 text-center font-sans-semibold text-level-3 text-text-primary">
            {{ userItem.deletedAt ? 'null' : userItem.creator }}
          </div>
          <div class="project-text mt-2 text-level-5 text-text-tertiary">
            <ClientOnly>
              <icon-font class="project-text-icon" type="icon-creative-line" />
            </ClientOnly>
            <span class="making-count">
              {{ userItem.makingCount }}
              Creations
            </span>
          </div>
          <FollowButton
            v-bind="$attrs"
            :id="userItem[idKey]"
            :name="userItem.creator"
            size="large"
            shape="round"
            default-type="primary"
            my-follow-type="default"
            class="follow-btn mt-4"
            :is-my-follow="userItem.isFollowed"
            content-type="user"
            @click.prevent=""
            @change="handleFollowChange"
          />
        </div>
      </div>

      <div class="xs-block-user">
        <!-- <template v-if="actions?.length">
          <eclipse-action :actions="actions" class="action-eclipse-icon" type="text" @toggle-actions="handleToggleActions" />
        </template> -->

        <!-- <a-space direction="horizontal" :size="12">
          <a-image
            class="user-img"
            :src="userItem.deletedAt ? deletedUseAvatarImg : getOssProcessedImage(userItem.headpic.split('?')[0], `m_fill,w_90,h_90`, 'webp')"
            width="90px"
            height="90px"
            :preview="false"
          />
          <a-space direction="vertical" align="start" :size="4">
            <span class="user-name one-line-ellipsis font-semibold">
              {{ userItem.deletedAt ? 'null' : userItem.name }}
            </span>
            <a-space :size="4" class="project-text">
              <icon-font class="project-text-icon" type="icon-creative-line" />
              <span class="making-count">
                {{ userItem.makingCount }}
                Projects
              </span>
            </a-space>
          </a-space>
        </a-space> -->
        <!-- <follow-button
          :id="userItem[idKey]"
          :name="userItem.name"
          class="follow-btn"
          :is-my-follow="userItem.isFollowed"
          content-type="user"
          @click.prevent=""
          @change="handleFollowChange"
        /> -->
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import deletedUseAvatarImg from '@/assets/img/deleted-user-avatar.png';
import PRIME_CREATOR from 'assets/img/creator/premium-creator-icon.png';

type PropsType = {
  userItem: any;
  actions?: string[];
  idKey?: string;
};
type EmitsType = {
  (e: 'toggleActions', type: string): void;
  (e: 'followChange', val: boolean): void;
  (e: 'clickItem'): void; // for search result page
};
const emits = defineEmits<EmitsType>();
const props = withDefaults(defineProps<PropsType>(), {
  idKey: 'objectId',
  actions: () => [],
});
// const handleToggleActions = (type: string) => {
//   emits('toggleActions', type);
// };

const topMakings = computed(() => props.userItem.topMakings);

const handleFollowChange = (val: boolean) => {
  emits('followChange', val);
};

function hanldeClickItem() {
  emits('clickItem');
}
</script>

<style lang="less" scoped>
.block-user-link {
  transition-property: box-shadow, transform;
  transition-duration: 0.2s;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }
}
</style>
