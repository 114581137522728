/**
 * @description 立即执行函数
 * @param fn 被执行函数
 * @param args 被执行函数参数
 */
export const call = <R, A extends any[]>(fn: (...args: A) => R, ...args: A): R => {
  return fn.apply(globalThis, args ?? []);
};

/**
 * @description 尝试执行函数，被执行函数报错时，不会抛出异常
 * @param fn 被执行函数
 * @param args 被执行函数参数
 */
export const tryCall = <R, A extends any[]>(fn: (...args: A) => R, ...args: A): R | undefined => {
  try {
    return fn.apply(globalThis, args ?? []);
  } catch (e) {
    console.error(e);
  }
};

/**
 * 尝试异步调用函数
 *
 * @param fn 异步函数
 * @param args 传递给异步函数的参数列表
 * @returns 返回异步函数的执行结果，如果执行出错则返回void
 * @template A 传递给异步函数的参数类型列表
 * @template R 异步函数的返回类型
 */
export async function tryAsyncCall<A extends any[], R>(fn: (...args: A) => Promise<R>, ...args: A): Promise<R | void> {
  try {
    return await fn.apply(globalThis, args ?? []);
  } catch (err) {
    console.error(err);
  }
}

/**
 * 尝试异步执行函数并捕获异常
 *
 * @param fn 需要执行的异步函数
 * @param args 传递给异步函数的参数列表
 * @returns 返回值为一个元组，包含异步函数的返回值和可能抛出的异常。如果函数执行成功，则异常为 null；如果函数执行失败，则返回值为 null，异常为抛出的错误对象。
 * @template A 传递给异步函数的参数类型列表
 * @template R 异步函数的返回类型
 */
export async function tryAsyncCatch<A extends any[], R>(fn: (...args: A) => Promise<R>, ...args: A): Promise<[R, null | unknown]> {
  const res: [R, null | unknown] = [null as unknown as R, null];
  try {
    res[0] = await fn.apply(globalThis, args ?? []);
    return res;
  } catch (err) {
    res[1] = err;
    return res;
  }
}
