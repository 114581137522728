import type { BenefitItem, SubscriptionInfo } from '~/components/subscription/types';
import type { SubscriptionPlan } from '~/components/subscription/constant';

export const getUserSubscriptionInfoApi = defineFindApi({
  url: '/community/v1/web/subscription',
  method: HttpMethod.GET,
  respTy: RespTy.Json<SubscriptionInfo>(),
});

export const getSubscriptionConfigApi = defineFindApi({
  url: '/community/v1/web/subscription/config',
  method: HttpMethod.GET,
  respTy: RespTy.Json<
    {
      type: SubscriptionPlan;
      price: string;
      trialPeriodDay: number;
      items?: BenefitItem[];
    }[]
  >(),
});

export const subscribeApi = defineFindApi({
  url: '/community/v1/web/subscription',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{
    type: SubscriptionPlan;
    successUrl: string;
    cancelUrl: string;
  }>(),
  respTy: RespTy.Json<{
    id: string;
    payUrl: string;
  }>(),
});

export const cancelSubscriptionApi = defineFindApi({
  url: '/community/v1/web/subscription',
  method: HttpMethod.DELETE,
});

export const upgradeSubscriptionApi = defineFindApi({
  url: '/community/v1/web/subscription',
  method: HttpMethod.PUT,
  paramTy: ParamTy.Json<{
    type: SubscriptionPlan;
  }>(),
});

export const getSubscriptionOrderByIdApi = defineFindApi({
  url: '/community/v1/web/subscription/order/:id',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    id: string;
  }>(),
});

export const pauseSubscriptionApi = defineFindApi({
  url: '/community/v1/web/subscription/pause-payment',
  method: HttpMethod.POST,
});

export const reuseSubscriptionApi = defineFindApi({
  url: '/community/v1/web/subscription/resume-payment',
  method: HttpMethod.POST,
});
