export class PromiseController<T = void> {
  #promise: Promise<T>;
  #resolve!: (value: T) => void;
  #reject!: (reason?: any) => void;

  constructor() {
    this.#promise = new Promise<T>((resolve, reject) => {
      this.#resolve = resolve;
      this.#reject = reject;
    });
  }

  get promise() {
    return this.#promise;
  }

  public resolve(value: T) {
    this.#resolve?.(value);
  }

  public reject(reason?: any) {
    this.#reject?.(reason);
  }
}
