import { Methods, request, REQUEST_SEVER } from '@/utils/request';

// 获取discussion详情
export const getDiscussionDetailApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/discussion/${id}`,
    method: Methods.GET,
  });
};

// 创建discussion讨论
export const createDiscussionApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/discussion',
    method: Methods.POST,
    data,
  });
};

// 修改discussion讨论
export const EditDiscussionApi = (id: number, data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/discussion/${id}`,
    method: Methods.PUT,
    data,
  });
};

// 获取推荐discussion讨论列表
export const getFeatureDiscussionsApi = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/discussions/feature',
    method: Methods.GET,
  });
};

// 获取discussion讨论列表
export const getDiscussionListApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/discussions',
    method: Methods.GET,
    params,
  });
};

// 删除讨论
export const deleteDiscussionApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/discussion/${id}`,
    method: Methods.DELETE,
  });
};

// 检查是否可以创建讨论
export const checkDiscussionAuth = defineFindApi({
  url: '/community/v1/web/discussion/check',
  method: Methods.GET,
});
