import { Methods, request, REQUEST_SEVER } from '@/utils/request';
// 修改回答
export const editAnswerApi = (id: number, data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/answer/${id}`,
    method: Methods.PUT,
    data,
  });
};

// 删除回答
export const deleteAnswerApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/answer/${id}`,
    method: Methods.DELETE,
  });
};

// 获取回答列表
export const getAnswerListApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT](
    {
      url: '/community/v1/web/answers',
      method: Methods.GET,
      params,
    },
    {
      lazy: true,
      server: false,
    },
  );
};

// 回答问题
export const doAnswerApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/answer',
    method: Methods.POST,
    data,
  });
};

// 获取回答列表
export const getAnswerInfoApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/answer/${id}`,
    method: Methods.GET,
  });
};
