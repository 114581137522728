import { isFunction } from 'lodash-es';
import type { Fn } from '~/types/fns';

/**
 * 在下一帧执行时返回Promise，用于异步等待下一帧渲染完成。
 *
 * @returns 返回一个Promise对象，该对象在下一帧执行完成时解析。
 */
export function nextFrame(): Promise<void>;
/**
 * 在下一帧执行回调函数
 *
 * @param cb 回调函数，不接受参数且没有返回值
 * @returns 无返回值
 */
export function nextFrame(cb: Fn<[], void>): void;
export function nextFrame(cb?: Fn<[], void>) {
  if (isFunction(cb)) {
    requestAnimationFrame(cb);
  } else {
    return new Promise<void>((resolve) => {
      requestAnimationFrame(() => resolve());
    });
  }
}
