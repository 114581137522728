import type { AdvertsRequest } from '@/components/ad/interface';
import { Methods, request, REQUEST_SEVER } from '~/utils/request';
/**
 * @description 机器&材料页广告位
 * @param params {
 *   type: 枚举值：machine-机器页 、material-耗材页、machineContent-机器内容页、materialContent-耗材内容页、collectTop-合集顶部、collectFlow-合集信息流、search-搜索词
 *   collectId: 合集id
 * }
 */
export const getAdvertsExplore = (data: AdvertsRequest) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/adverts',
    method: Methods.POST,
    data,
  });
};
