<template>
  <a-popover v-model:open="open" placement="bottom" :arrow="false" :auto-adjust-overflow="true">
    <icon-font
      type="icon-more-fill"
      :class="[`h-[22px] w-[22px] rotate-90 rounded-full`, `flex items-center justify-center`, `text-text-primary hover:bg-background-2`]"
      @click.prevent.stop=""
    />
    <template #content>
      <div>
        <div class="flex w-[218px] cursor-pointer flex-nowrap space-x-3 text-text-primary" @click="onCommand('feedback')">
          <icon-font type="icon-unlike" class="text-[20px]" />
          <span class="text-level-4">See fewer assets like this</span>
        </div>
      </div>
    </template>
  </a-popover>
</template>

<script setup lang="ts">
const emits = defineEmits<{
  (e: 'command', type: string): void;
}>();

const onCommand = (cmd: string) => {
  emits('command', cmd);
  open.value = false;
};

const open = ref(false);
</script>
