import type { NoticeType } from '@/types/notice';
import { request, REQUEST_SEVER } from '@/utils/request';

// 获取全部消息列表   status: unread 未读 | read 已读 | ignore 忽略
export const getAllNoticeListApi = (params: {
  current: number;
  pageSize: number;
  type?: NoticeType;
  status?: 'unread' | 'read' | 'ignore';
}) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/messages',
    method: 'GET',
    params,
  });
};

// 获取未读消息列表
export const getUnreadNoticeListApi = (params: {
  current: number;
  pageSize: number;
  type?: string;
}) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/messages/unread',
    method: 'GET',
    params,
  });
};

// 全部消息标记已读, 传 type 表示该类型已读
export const allNoticeReadApi = (type?: NoticeType | null) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/messages/read',
    method: 'POST',
    data: { type },
  });
};

// 目标消息标记已读
export const tagNoticeReadApi = (data: { type: NoticeType; id: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/message/read`,
    method: 'POST',
    data,
  });
};

// 获取用户消息设置
export const getNoticeSettingsApi = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/settings/message',
    method: 'GET',
  });
};

// 提交用户消息设置
export const postNoticeSettingsApi = (data: {
  type: NoticeType;
  value: boolean;
}) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/my/settings/message',
    method: 'POST',
    data,
  });
};
