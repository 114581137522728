import type { OBJECT_TYPE } from '@/types/constant';
import { Methods, request, REQUEST_SEVER } from '@/utils/request';
import { defineFindApi } from '~/utils/fetch/define-api';

// 获取社区基础数据
export const getBaseDataApi = (type: string, config?: any) => {
  return request[REQUEST_SEVER.COMMON](
    {
      url: `/efficacy/v1/data/type/${type}/items`,
      method: Methods.GET,
    },
    {
      ...config,
    },
  );
};

// 获取二次创作基于的原作列表
export const getRemixListApi = ({ id, params }: { id: number; params: any }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}/remix`,
    method: Methods.GET,
    params,
  });
};

// 获取用户角色
export const getUserRoleApi = defineCommonApi({
  url: '/efficacy/v1/roles',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    d: string;
  }>(),
});

// 删除评论
export const deleteCommentApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/comment/${id}`,
    method: Methods.DELETE,
  });
};

// 获取评论列表
export const getCommentListApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/comments',
    method: Methods.GET,
    params,
  });
};

// 用户评论
export const doCommentApi = (data: { objectType: OBJECT_TYPE; objectId: number; content: any; images?: string[] }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/comment',
    method: Methods.POST,
    data,
  });
};

// 获取目标评论pg
export const getTagCommentApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/comment/${id}`,
    method: 'GET',
  });
};

// 获取标签列表
export const getTagCount = (params: { objectType: string; q: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/tags/count`,
    method: 'GET',
    params,
  });
};
// 获取Top10标签列表
export const getTopTagCount = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/tag/top`,
    method: 'GET',
  });
};
// 获取耗材列表
export const getMaterialList = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/data/type/community_material/items`,
    method: 'GET',
  });
};
// 设置默认协议
export const setDefaultLicense = (data: { code: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/settings/license`,
    method: 'POST',
    data,
  });
};
// 获取默认协议
export const getDefaultLicense = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/my/settings/license`,
    method: 'GET',
  });
};
// 获取难度等级列表
export const getDiffucultyLevelData = () => {
  return request[REQUEST_SEVER.COMMON]({
    url: `/efficacy/v1/data/type/community_difficulty_level/items`,
    method: 'GET',
  });
};

// 根据ids获取bundle信息
export const getBundleListByIds = defineFindApi({
  url: '/community/v1/web/making/bundle/subs',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any[]>(),
});

// 获取导航栏数据
export const getNavCatalogsApi = defineFindApi({
  url: '/community/v1/web/navigations',
  respTy: RespTy.Json<any[]>(),
});

export const getNavImgApi = defineFindApi({
  url: '/community/v1/web/navigation/images/all',
  respTy: RespTy.Json<any>(),
});

// 获取国家列表
export const getCountryListData = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/country`,
    method: 'GET',
  });
};
