export enum COMMUNITY_BASE_DATA {
  DEVICE = 'community_device',
  POWER = 'community_laser_power',
  MATERIAL = 'community_material',
  SOFTWARE = 'community_software',
  DISCUSSION = 'discussion_category',
  TOPICS = 'community_question_topic',
  PROCESS = 'community_processing_type',
  BITMAP = 'community_bitmap_mode',
  LICENSE = 'community_creative_license',
  SCENARIO = 'community_making_design_scenario',
  ACCESSORY = 'community_accessory',
  MODE = 'community_processing_mode',
  LASER = 'community_laser_source',
}

export enum CONTENT_TYPE {
  HOWTOS = 'howtos',
  TIPS = 'tips',
  INSPIRATION = 'inspiration',
  DISCUSSION = 'discussion',
  QUESTION = 'question',
  CREATORS = 'user',
  COLLECTION = 'collection',
  BUNDLE = 'bundle',
  PROJECT = 'project',
  XTHINGS = 'xthings',
  DESIGNS = 'designs',
}

export enum OBJECT_TYPE {
  MAKING = 'making',
  COLLECTION = 'collection',
  QUESTION = 'question',
  DISCUSSION = 'discussion',
  ANSWER = 'answer',
  COMMENT = 'comment',
  USER = 'user',
  BLOCKITEM = 'blockItem',
}

export enum USER_ACTION {
  LIKE = 'like',
  UNLIKE = 'unlike',
  VIEW = 'VIEW',
  FAVORITE = 'favorite',
  FOLLOW = 'follow',
  COMMENT = 'comment',
}

/**
 * 这篇内容的状态
 */
export enum DETAIL_TYPE {
  NEW = 'new',
  PUBLISHED = 'published', // 已发布
}

export const DIRECTORY_ID_KEY = 'level';

interface contentType {
  icon: string;
  name: string;
}

interface StringMap {
  [key: string]: contentType;
}

/**
 * 作品类型
 */
export const MakingTypeMap: StringMap = {
  // 归为 Project
  [CONTENT_TYPE.PROJECT]: {
    icon: 'icon-Templates',
    name: 'Project',
  },
  [CONTENT_TYPE.HOWTOS]: {
    icon: 'icon-Templates',
    name: 'Project',
  },
  // 归为 Project
  [CONTENT_TYPE.INSPIRATION]: {
    icon: 'icon-Templates',
    name: 'Project',
  },
  [CONTENT_TYPE.BUNDLE]: {
    icon: 'icon-a-Bundle1',
    name: 'Bundle',
  },
  [CONTENT_TYPE.DESIGNS]: {
    icon: 'icon-a-30-Desing',
    name: 'Design',
  },
  // 归为 Project
  [CONTENT_TYPE.XTHINGS]: {
    icon: 'icon-Templates',
    name: 'Project',
  },
};

export const enum MakingAttr {
  PGC = 'free',
  UGC = 'discount',
}
