import { getBlockExtDataListApi } from '@/api/explore';
import { getPreferenceMakings } from '@/api/preference';
import { merge } from 'lodash-es';
import { v4 } from 'uuid';
import { usePreference } from '~/stores/preference';

interface MakingExtMap {
  [key: number]: {
    id: number; // 作品id
    extra: {
      blockCover: string; // 运营配置的封面图
    };
    origPrice: number; // 原价
    sellTag: string; // 售卖标签
    hasDiscount: boolean; // 是否有折扣活动
    discount: {
      discountPercent: number; // 折扣比例
      disPrice: number; // 折扣价格
    };
  };
}

export function useForYou() {
  const status = reactive({
    loading: false,
    finished: false,
  });
  const inited = useState('FOR_YOU_INITeD', () => false);
  const items = useState('FOR_YOU_ITEMS', () => [] as any[]);
  const makingIds = new Set<number>();
  const paging: any = {
    current: 0,
    pageSize: 20,
  };
  const reset = async () => {
    items.value = [];
    makingIds.clear();
    Object.assign(status, {
      loading: false,
      finished: false,
    });
    Object.assign(paging, {
      current: 0,
      pageSize: 20,
    });
    await infiniteLoad();
  };
  const loadMore = async () => {
    if (status.loading) {
      return;
    }
    try {
      status.loading = true;

      const cid = useCookie('cid');
      if (!cid.value) {
        cid.value = v4();
      }

      paging.current++;
      const res = await getPreferenceMakings.fetch({
        ...paging,
        cid: cid.value,
      });

      const data = res?.data;

      if (res.code !== 0) {
        throw new Error('unkown system error');
      }

      const list = data?.list ?? [];
      const pagination = data?.pagination ?? { current: 0, total: 0, pageSize: 20 };
      Object.assign(paging, pagination);
      status.finished = paging.current >= Math.ceil(paging.total / paging.pageSize);

      const currentList = handleDataDeduplication(unref(items), list);
      updateBlockMakingIds(currentList);

      return currentList;
    } catch (error) {
      console.warn(error);
      status.finished = true;
    } finally {
      status.loading = false;
    }
  };
  const infiniteLoad = async () => {
    const arr = (await loadMore()) ?? [];
    items.value.push(...arr);
  };

  // 收集making id
  const updateBlockMakingIds = (arr: any[]) => {
    const newIds = arr.filter((item) => !makingIds.has(item.id));
    newIds.forEach((item) => makingIds.add(item.id));
  };
  const makingExtMapData = ref<MakingExtMap>({});
  const getLatestMakings = async (ids: Set<number>) => {
    try {
      const res = await getBlockExtDataListApi.fetch({ ids: [...ids] });
      makingExtMapData.value = res?.data ?? {};
    } catch (error) {
      console.error(error);
    }
  };
  // 更新案例最新数据
  const updateMakingList = async () => {
    try {
      await getLatestMakings(makingIds);
      items.value = remixMakingData(items.value);
    } catch (err) {
      console.error(err);
    }
  };
  // 合并案例数据, 去掉删除的数据
  const remixMakingData = (list: Array<any>) => {
    return list
      .map((item: any) => {
        const makingExt = makingExtMapData.value?.[item.id] || {};
        return merge(item, makingExt);
      })
      .filter((item) => !Boolean(item.isDeleted));
  };

  const init = async () => {
    if (inited.value) {
      return;
    }
    await infiniteLoad();
    inited.value = true;
  };

  const preference = usePreference();
  watch(() => preference.selectedIds, reset, { deep: true });

  return {
    items,
    status,
    init,
    infiniteLoad,
    updateMakingList,
  };
}
