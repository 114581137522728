import type { ItemType } from '@/types';
import { Methods, request, REQUEST_SEVER } from '@/utils/request';

// 新增创作
export const createProjectApi = (data: ItemType) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/making',
    method: Methods.POST,
    data,
  });
};

// 编辑创作
export const editProjectApi = (id: number, data: ItemType) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}`,
    method: Methods.PUT,
    data,
  });
};
// 新增创作v2
export const createProjectApiV2 = (type: string, data: ItemType) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v2/web/making/${type}`,
    method: Methods.POST,
    data,
  });
};

// 编辑创作v2
export const editProjectApiV2 = (type: string, id: number, data: ItemType) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v2/web/making/${type}/${id}`,
    method: Methods.PUT,
    data,
  });
};

// 获取创作详情
export const getProjectDetailApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}`,
    method: 'GET',
  });
};

// 删除创作
export const deleteMakingApi = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/making/${id}`,
    method: Methods.DELETE,
  });
};

// 获取所有创作
export const getProjectListApi = (params: {
  current: number;
  pageSize: number;
  type?: string;
  q?: string;
  deviceId?: number;
  materialId?: number;
}) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/makings`,
    method: Methods.GET,
    params,
  });
};

// 获取所有创作
export const getProjectListFetchApi = defineFindApi({
  url: '/community/v1/web/makings',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    current: number;
    pageSize: number;
    type?: string;
    q?: string;
    deviceId?: number;
    materialId?: number;
    cid?: any;
  }>(),
  respTy: RespTy.Json<{
    list: any[];
    pagination: {
      current: number;
      total: number;
      pageSize: number;
    };
  }>(),
});

// 获取举报状态
export const getReportStatusApi = (params: { objectType: string; objectId: number }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/report`,
    method: Methods.GET,
    params,
  });
};

// 举报创作
export const reportProjectApi = (data: { objectId: number; type: string; content?: string; images?: any[] }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/report`,
    method: Methods.POST,
    data,
  });
};
// 原作品筛选-license
export const getLicenseList = (params: { current: number; pageSize: number; q?: string; licenseIds?: any[] }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/license/makings`,
    method: Methods.GET,
    params,
  });
};
// 链接解析-标题&缩略图
export const parseUrl = (params: { url: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/url/parse`,
    method: Methods.GET,
    params,
  });
};
// 新增订单
export const payOrderApi = (params: { makingId: number; successUrl: string; cancelUrl: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/order',
    method: Methods.POST,
    data: params,
  });
};
// 查询单个订单
export const getOrderApi = (params: { orderNumber: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/web/order/${params.orderNumber}`,
    method: Methods.GET,
  });
};
// 获取个人订单列表
export const getOrderListApi = (params: { current: number; pageSize: number; status?: string }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/orders',
    method: Methods.GET,
    params,
  });
};

// 给链接加签名
export const postSignUrls = (data: { urls: string[] }) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/sign-urls',
    method: Methods.POST,
    data,
  });
};

// 给链接加签名(使用$fetch)
export const postSignUrlsFetch = defineFindApi({
  url: '/community/v1/web/sign-urls',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{ urls: string[] }>(),
  respTy: RespTy.Json<{ [key: string]: string }>(),
});

// 获取bom列表
export const getBomList = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/xcc/bom/products',
    method: Methods.GET,
  });
};
// 获取xcc商品trackId
export const getBomTrackId = (id: number) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: `/community/v1/xcc/bom/${id}/track`,
    method: Methods.GET,
  });
};
