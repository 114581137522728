import type { ListWrap } from '~/types';
import { defineFindApi } from '~/utils/fetch/define-api';

// 获取用户已设置过的喜好标签
export const getPreferenceTags = defineFindApi({
  url: '/community/v1/web/preference-tags',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query<{
    cid: string;
  }>(),
  respTy: RespTy.Json<{
    isFirst: boolean;
    tags: number[];
  }>(),
});

// 设置用户已设置过的喜好标签
export const uploadPreferenceTags = defineFindApi({
  url: '/community/v1/web/preference-tags',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json<{
    cid: string;
    tags: number[];
  }>(),
  respTy: RespTy.Json<any>(),
});

// 获取用户可选的喜好标签
export const getPreferenceOptions = defineFindApi({
  url: '/community/v1/web/preference-tags/optional',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any[]>(),
});

// 获取喜好推荐列表
export const getPreferenceMakings = defineFindApi({
  url: '/community/v1/recommend/makings',
  method: HttpMethod.GET,
  respTy: RespTy.Json<ListWrap>(),
  paramTy: ParamTy.Query<{
    cid: string;
    current: number;
    pageSize: number;
  }>(),
});

export const getMakingPreference = defineFindApi({
  url: '/community/v1/web/preference-tags/making',
  method: HttpMethod.POST,
  paramTy: ParamTy.Query<{
    makingId: number; // 案例id
  }>(),
  respTy: RespTy.Json<{
    id: number; // 记录id
    makingId: number; // 案例id
    reason: string; // 理由
    setTime: number; // 记录时间
  }>(),
});
export const addMakingPreference = defineFindApi({
  url: '/community/v1/recommend/feedback',
  method: HttpMethod.POST,
  paramTy: ParamTy.Query<{
    cid: string;
    makingId: number;
    reason: string;
  }>(),
  respTy: RespTy.Json<{
    id: number; // 记录id
  }>(),
});

export const updateMakingPreference = defineFindApi({
  url: '/community/v1/web/preference-tags/making',
  method: HttpMethod.PUT,
  paramTy: ParamTy.Query<{
    id: number; // 记录id
    cid: string;
    reason: string;
  }>(),
  respTy: RespTy.Json<any>(),
});

export const deleteMakingPreference = defineFindApi({
  url: '/community/v1/recommend/feedback/:id',
  method: HttpMethod.DELETE,
  paramTy: ParamTy.Query<{
    id: number; // 记录id
  }>(),
  respTy: RespTy.Json<any>(),
});
