import type { SubscriptionPlan } from '~/components/subscription/constant';

export const noticeXcsSubscriptionUpdate = (data: { plan: SubscriptionPlan }) => {
  console.log('df-subscription-info-update: >>>>>> ', data);
  window.parent.postMessage(
    {
      action: 'df-subscription-info-update',
      from: 'df',
      platform: 'df',
      data,
    },
    '*',
  );
};
