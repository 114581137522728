import { getPreferenceOptions, getPreferenceTags, uploadPreferenceTags } from '@/api/preference';
import { isArray, isNil } from 'lodash-es';
import { defineStore } from 'pinia';
import { v4 } from 'uuid';

const CID_KEY = 'cid';
export const usePreference = defineStore('preference', () => {
  const open = ref(false);
  // 是否第一次设置
  const inited = ref(false);
  const isFirstSet = ref(false);
  const groups = ref();
  const tagMap = new Map<number, any>();
  const selectedIds = shallowRef(new Set<number>());
  const selected = computed(() => Array.from(selectedIds.value).map((id) => tagMap.get(id)));

  const { sensorForYouTagPopShow, sensorForYouTagClose, sensorForYouTagChoose } = useSensors();

  const init = async () => {
    const cid = Cookie.get(CID_KEY);
    if (isNil(cid)) {
      Cookie.set(CID_KEY, v4());
    }

    // 获取可选喜好标签配置 + 获取该设备\用户的历史偏好设置
    await Promise.all([getUserPreferenceOptions(), getUserPreferenceSettings()]);
    await updatePreferenceTags();
    inited.value = true;
  };
  const firstSet = () => {
    // 如果没有可选标签，不展示
    if (!groups?.value?.length) {
      return;
    }

    // 如果已经设置过，默认不展示
    try {
      const preference = localStorage.getItem('preference');
      if (preference) {
        const opt = JSON.parse(preference);
        if (opt.isSet) {
          return;
        }
      }
    } catch (err: any) {
      localStorage.setItem('preference', JSON.stringify({}));
    }

    // 如果未设置过, 默认展示并设置展示缓存
    if (isFirstSet.value) {
      localStorage.removeItem('preference');
      saveToLocal([]);
      openPreferenceModal();
      return;
    }
  };
  const saveToLocal = (tags: number[]) => {
    const opt = { isSet: true, tags };
    localStorage.setItem('preference', JSON.stringify(opt));
  };
  const update = async (ids: number[]) => {
    const cid = Cookie.get(CID_KEY) as string;
    const tags = [...ids];

    // 上传用户偏好
    const res = await uploadPreferenceTags.fetch({ cid, tags });

    if (res?.code !== 0) {
      message.error('Failed to save preference settings, please try again later');
      return;
    }

    selectedIds.value = new Set(ids);
    saveToLocal(tags);

    sensorForYouTagChoose({
      element_name: selected.value.map((item) => item.name).join(','),
    });
  };
  const getUserPreferenceOptions = async () => {
    const config = {
      cid: Cookie.get(CID_KEY) as string,
    };

    const res = await getPreferenceOptions.fetch();
    const data = res?.data ?? [];

    if (data && data.length) {
      groups.value = data.map((item: any) => {
        return {
          name: item.typeName,
          items: item.tagGroups.map((tag: any) => {
            tagMap.set(tag.id, tag);
            return {
              id: tag.id,
              name: tag.name,
            };
          }),
        };
      });
    }

    return config;
  };
  // 获取用户偏好设置
  const getUserPreferenceSettings = async () => {
    // 获取用户偏好
    const res = await getPreferenceTags.fetch({
      cid: Cookie.get(CID_KEY) as string,
    });

    const data = res?.data;

    const isFirst = data?.isFirst;
    const tags = data?.tags;

    if (tags && tags.length) {
      tags.forEach((tag: any) => {
        selectedIds.value.add(tag);
      });
    }

    isFirstSet.value = isFirst;
  };

  // 初始化时，用本地的 tags 覆盖用户的 tags
  const updatePreferenceTags = async () => {
    try {
      const preference = localStorage.getItem('preference');
      if (preference || !isFirstSet.value) {
        const opt = preference && JSON.parse(preference);
        const tags = isArray(opt?.tags) && opt?.isSet ? opt?.tags : [];
        const set = new Set<number>(tags);
        selectedIds.value.forEach((tag: any) => {
          set.add(tag);
        });
        if (set.size > 0) {
          await update(Array.from(set));
        }
      }
    } catch (err: any) {
      console.log('err :>> ', err.message);
      saveToLocal([...Array.from(selectedIds.value)]);
    }
  };

  const openPreferenceModal = () => {
    open.value = true;
    sensorForYouTagPopShow();
  };
  const closePreferenceModal = () => {
    open.value = false;
    sensorForYouTagClose();
  };

  return {
    inited,
    open,
    isFirstSet,
    groups,
    selected,
    selectedIds,
    init,
    firstSet,
    update,
    openPreferenceModal,
    closePreferenceModal,
  };
});
