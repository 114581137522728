import { defineStore } from 'pinia';
import { isString } from 'lodash-es';
import type { Fn, Nilable } from '~/types/fns';
import type {
  SubscriptionConfig,
  FeedbackStatus,
  SubscriptionHooks,
  SubscriptionFlowOptions,
  BuyOrSubscribeModalOptions,
  SubscribeOptions,
} from '~/components/subscription/types';
import {
  BenefitBelong,
  SubscriptionPlan,
  SubscriptionStatus,
  SubscribeFailedTip,
  UpgradeFailedTip,
  UpgradeTimeoutTip,
} from '~/components/subscription/constant';
import { PromiseController } from '~/utils/fns/promise';
import {
  getSubscriptionConfigApi,
  getUserSubscriptionInfoApi,
  pauseSubscriptionApi,
  reuseSubscriptionApi,
  subscribeApi,
  upgradeSubscriptionApi,
} from '~/api/subscription';
import { noticeXcsSubscriptionUpdate } from '~/components/subscription/utils';
import { callNewPage } from '~/utils/call-it';
import { Modal } from 'ant-design-vue';

export const useSubscription = defineStore('subscription', () => {
  const inited = ref(false);
  const plan = ref<SubscriptionPlan>(SubscriptionPlan.FREE);
  const status = ref<SubscriptionStatus>(SubscriptionStatus.INCOMPLETE);
  const isExpired = computed(() => status.value === SubscriptionStatus.EXPIRED);
  const nextPaymentTime = ref<number | null>(null);
  const isRenewPaused = ref(false);
  const isTrial = ref(true);

  const route = useRoute();
  const _config = reactive<SubscriptionConfig>({} as any);
  const config = computed(() => {
    return Object.keys(_config)
      .map((key) => _config[key as unknown as SubscriptionPlan])
      .reduce((acc, cur) => {
        const dfBenefits = cur.benefits.filter((item) => item.belong === BenefitBelong.DF);
        const aiBenefits = cur.benefits.filter((item) => item.belong === BenefitBelong.AI_MAKE);
        acc[cur.plan] = {
          ...cur,
          benefits: route.path.startsWith('/aimake') ? [...aiBenefits, ...dfBenefits] : [...dfBenefits, ...aiBenefits],
        };
        return acc;
      }, {} as SubscriptionConfig);
  });

  const flow = new SubscriptionFlow();

  const plansModal = usePlansModal();
  const feedbackModal = useFeedbackModal();
  const buyOrSubscribeModal = useBuyOrSubscribeModal();

  const reset = async () => {
    const lastPlan = plan.value;
    try {
      await Promise.all([initConfig(), initUserSubscription()]);
    } finally {
      if (plan.value !== lastPlan) {
        noticeXcsSubscriptionUpdate({ plan: plan.value });
      }
    }
  };
  const initConfig = async () => {
    try {
      const res = await getSubscriptionConfigApi.fetch();
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message);
      }
      res.data.forEach((item) => {
        _config[item.type] = {
          plan: item.type,
          price: item.price,
          trialDay: item.trialPeriodDay,
          benefits: item.items ?? [],
        };
      });
    } catch (e) {
      console.error(e);
    }
  };
  const initUserSubscription = async () => {
    try {
      const res = await getUserSubscriptionInfoApi.fetch();
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message);
      }

      plan.value = res.data.type ?? plan.value;
      status.value = res.data.status ?? status.value;
      nextPaymentTime.value = res.data.currentPeriodEnd;
      isRenewPaused.value = res.data.isPausePayment ?? isRenewPaused.value;
      isTrial.value = res.data.isTrial ?? isTrial.value;
      inited.value = true;
    } catch (e) {
      console.error(e);
    }
  };

  const checkPlan = async (decide: Fn<[SubscriptionPlan, SubscriptionPlan], boolean>) => {
    try {
      const lastPlan = plan.value;
      const res = await getUserSubscriptionInfoApi.fetch();
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message || '订阅失败');
      }
      const newPlan = res.data.type;
      return decide(lastPlan, newPlan);
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const subscribeFlow = (opts: SubscribeOptions) => {
    return flow.run({ ...opts, runner: () => subscribe(opts.plan) });
  };
  const subscribe = async (_plan: SubscriptionPlan.PRO | SubscriptionPlan.PRO_PLUS) => {
    if (isInApp()) {
      await xcsSubscribe(_plan);
    } else {
      await webSubscribe(_plan);
    }
  };
  const webSubscribe = async (_plan: SubscriptionPlan.PRO | SubscriptionPlan.PRO_PLUS) => {
    try {
      const res = await subscribeApi.fetch({
        type: _plan,
        successUrl: globalThis.location.href,
        cancelUrl: globalThis.location.href,
      });
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message || '订阅失败');
      }
      callNewPage(res.data.payUrl, {
        timeout: 1000 * 10,
        target: '_self',
      });
    } catch (e) {
      console.error(e);
      failedFeedback(SubscribeFailedTip);
    }
  };
  const xcsSubscribe = async (_plan: SubscriptionPlan.PRO | SubscriptionPlan.PRO_PLUS, retry = false) => {
    try {
      const res = await subscribeApi.fetch({
        type: _plan,
        successUrl: getPaymentLink(),
        cancelUrl: globalThis.location.href,
      });
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message || '订阅失败');
      }
      if (!retry) {
        plansModal.close();
        Modal.confirm({
          title: 'Have you completed the payment?',
          centered: true,
          okText: 'Yes',
          cancelText: 'No, I want to retry',
          transitionName: '',
          async onOk() {
            if (await checkPlan((lastPlan, newPlan) => lastPlan !== newPlan)) {
              flow.complete();
              feedbackModal.succeed();
              await reset();
            } else {
              failedFeedback(SubscribeFailedTip);
            }
          },
          onCancel() {
            xcsSubscribe(_plan, true);
            return Promise.reject();
          },
        });
      }
      callNewPage(res.data.payUrl, {
        timeout: 1000 * 10,
        target: '_blank',
      });
    } catch (e) {
      console.error(e);
      failedFeedback(SubscribeFailedTip);
    }
  };

  const ticker = useTicker(
    async () => {
      if (await checkPlan((lastPlan, newPlan) => lastPlan < newPlan)) {
        ticker.pause();
        flow.complete();
        feedbackModal.succeed();
        await reset();
      } else if (ticker.tickCount >= 15) {
        ticker.pause();
        failedFeedback(UpgradeTimeoutTip.message);
      }
    },
    { auto: false, ms: 1000 },
  );
  const upgradeFlow = (opts?: SubscriptionFlowOptions) => {
    return flow.run({ ...opts, runner: upgrade });
  };
  const upgrade = async () => {
    try {
      feedbackModal.waiting('upgrade');
      const res = await upgradeSubscriptionApi.fetch({
        type: SubscriptionPlan.PRO_PLUS,
      });
      if (res.code !== RespCode.SUCCESS) {
        throw new Error(res.message || '订阅升级失败');
      }
      ticker.reset();
    } catch (e) {
      console.error(e);
      failedFeedback(UpgradeFailedTip.message);
    }
  };

  const pauseAutoRenew = async () => {
    const res = await pauseSubscriptionApi.fetch();
    if (res.code === RespCode.SUCCESS) {
      await reset();
    }
  };
  const reuseAutoRenew = async () => {
    const res = await reuseSubscriptionApi.fetch();
    if (res.code === RespCode.SUCCESS) {
      failedFeedback({
        title: 'Notification',
        message: 'The renewal fee will be deducted from your reserved payment method, the result can be viewed later in [My Space - Subscription].',
      });
      await reset();
    }
  };

  const openBuyOrSubscribeModal = (opts: BuyOrSubscribeModalOptions) => {
    return flow.run({
      ...opts,
      runner: () => {
        buyOrSubscribeModal.open(opts);
      },
    });
  };
  const openPlansModal = (opts?: SubscriptionFlowOptions) => {
    return flow.run({
      ...opts,
      runner: () => {
        plansModal.open();
      },
    });
  };

  const cancel = () => {
    flow.cancel();
  };
  const abort = (reason?: any) => {
    flow.abort(reason);
  };

  const failedFeedback = (opts: string | { title: string; message: string }) => {
    const _opts: any = isString(opts) ? { title: '', message: opts } : opts;
    flow.abort(_opts.message);
    feedbackModal.failed(_opts);
  };

  return {
    inited,
    plan,
    config,
    status,
    isTrial,
    isExpired,
    isRenewPaused,
    nextPaymentTime,
    flow,
    reset,
    initConfig,
    initUserSubscription,
    openBuyOrSubscribeModal,
    openPlansModal,
    subscribe,
    subscribeFlow,
    upgrade,
    upgradeFlow,
    pauseAutoRenew,
    reuseAutoRenew,
    cancel,
    abort,
  };
});

export const useBuyOrSubscribeModal = defineStore('subscription-buy-or-subscribe', () => {
  const show = ref(false);
  const options = reactive<Required<Omit<BuyOrSubscribeModalOptions, 'hooks' | 'domain'>>>({
    title: '',
    price: null,
    actionBtnText: '',
  });

  const open = (opts?: Omit<BuyOrSubscribeModalOptions, 'hooks' | 'domain'>) => {
    show.value = true;
    Object.assign(options, opts);
  };
  const close = () => {
    show.value = false;
  };
  return {
    show,
    options,
    open,
    close,
  };
});

export const usePlansModal = defineStore('subscription-plans-modal', () => {
  const show = ref(false);

  const buyOrSubscribeModal = useBuyOrSubscribeModal();
  const open = () => {
    buyOrSubscribeModal.close();
    show.value = true;
  };
  const close = () => {
    show.value = false;
  };
  return {
    show,
    open,
    close,
  };
});

export const useFeedbackModal = defineStore('subscription-feedback-modal', () => {
  const opts = reactive({
    title: '',
    message: '',
    action: '',
  });
  const status = ref<FeedbackStatus>('');

  const plansModal = usePlansModal();
  const waiting = (action?: 'subscribe' | 'upgrade') => {
    plansModal.close();
    status.value = 'waiting';
    opts.action = action ?? '';
  };
  const succeed = () => {
    plansModal.close();
    status.value = 'succeed';
  };
  const failed = (_opts: string | { title: string; message: string }) => {
    plansModal.close();
    Object.assign(opts, isString(_opts) ? { title: '', message: _opts } : _opts);
    status.value = 'failed';
  };
  const close = () => {
    status.value = '';
  };

  return {
    opts,
    status,
    waiting,
    succeed,
    failed,
    close,
  };
});

class SubscriptionFlow {
  _hooks?: SubscriptionHooks;
  _controller?: Nilable<PromiseController<boolean>>;

  emit(name: keyof SubscriptionHooks, ...args: any) {
    if (this._controller) {
      this._hooks?.[name]?.(...args);
      console.log(`SubscriptionFlow: ${name}`, args);
    }
  }

  run(opts: { runner: () => any; hooks?: SubscriptionHooks }) {
    if (!Cookie.get('utoken')) {
      const { $client } = getNuxtApp() as any;
      $client.openModal(() => {
        location.reload();
      });
      return Promise.reject('未登录');
    }
    if (!this._controller) {
      this._controller = new PromiseController<boolean>();
      this._hooks = opts.hooks ?? {};
    }

    opts.runner();
    return this._controller.promise;
  }

  complete() {
    this._controller?.resolve(true);
    this._controller = null;
  }

  abort(reason?: any) {
    this._controller?.reject(reason);
    this._controller = null;
  }

  cancel() {
    this._controller?.resolve(false);
    this._controller = null;
  }
}

// 获取支付链接
const getPaymentLink = call(() => {
  if (import.meta.env.VITE_PASSPORT_ENV === 'dev') {
    return () => {
      return `${location.origin}/subscription/pay-result`;
    };
  } else {
    return () => {
      const siteUrl = import.meta.env.VITE_PUBLIC_SITE_URL;
      const passportUrl = import.meta.env.VITE_PASSPORT_SITE_URL;
      const token = Cookie.get('utoken');
      if (!token) {
        throw new Error('token is required');
      }
      return `${passportUrl}/en/authcb?ut=${token}&r=${siteUrl}/subscription/pay-result`;
    };
  }
});
