/**
 * 环境判断
 */

// 是否存在 MeApi (目前在pc端xcs软件内才有值)
export function isMeApi() {
  return window?.parent?.MeApi;
}

/**
 * 在手机/iPad的xcs app为 真 ，在浏览器中为 假 (包括安卓和iOS)
 * 例如：
 * 做链接打开判断，若在 移动端app中，当前页打开链接
 * if (isAppWebkit()) {...}
 */
export function isAppWebkit() {
  return (window as any)?.LinkNative || (window as any)?.webkit?.messageHandlers?.LinkNative;
}

/**
 * 在ipad浏览器中为 真，在手机/电脑/ipad的xcs软件中都为 假
 * 例如：
 * 做链接打开判断，若在 iPad浏览器中，新开窗口到浏览器打开
 * if (isIpad()) {...}
 */
export function isIpad() {
  return !isAppWebkit() && (navigator?.userAgent?.match(/(iPad)/) || (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1));
}

/**
 * Electron 环境
 */
export function isElectron() {
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent?.indexOf('Electron') >= 0) {
    return true;
  }
  return false;
}
/**
 * 是否在pc xcs软件内部环境(xcs的pc端软件是使用 electron 构建的，其实只需要用isElectron()就可以判断是否在pc xcs软件内部环境)
 * 例如：
 * 做链接打开判断，若在 PC端app中，新开窗口到浏览器打开
 * if (isElectronXcs()) {...}
 */
export function isElectronXcs() {
  return isElectron() && window.parent.MeApi;
}

export function isMobileSystem() {
  return typeof window !== 'undefined' && /Mobi|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
// 是否为iPad系统
export function isIpadSystem() {
  return (
    typeof window !== 'undefined' &&
    Boolean(navigator.userAgent.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
  );
}
// 区分移动端和PC端
export function isMobileOrPad() {
  return isMobileSystem() || isIpadSystem();
}
// 根据设备信息判断是否为pc端
export const IsPC = () => {
  const userAgentInfo = navigator.userAgent as any;
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
    'Mobile',
    'iphone',
    'android',
    'windows phone',
    'ipod',
    'blackberry',
    'webos',
    'opera mini',
    'mobile',
    'tablet',
  ];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  // 进一步判断触控设备
  if (flag && navigator.maxTouchPoints > 0) {
    // 这里可以添加更多的判断条件来区分触控PC和移动设备
    if (userAgentInfo.indexOf('Windows NT') > 0 || userAgentInfo.indexOf('Macintosh') > 0) {
      flag = true; // 触控PC
    } else {
      flag = false; // 移动设备
    }
  }
  return flag;
};
